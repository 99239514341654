import { Checkbox } from '@/components/form/checkbox';
import { Dropzone } from '@/components/form/dropzone';
import { FormLabel } from '@/components/form/formlabel';
import { Input } from '@/components/form/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/form/select';
import { Textarea } from '@/components/form/textarea';
import { HSeparator } from '@/components/separator';
import {useParams} from "react-router-dom";
import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {ShowEvent, UpdateEventInformation, UploadBanner} from "@/services/events";
import moment from "moment";
import {clearErrors} from "@/reducers/auth";
import {setEvent} from "@/reducers/events";

export function OrgEventNewStep1() {
  const {slug} = useParams();
  const dispatch: AppDispatch = useDispatch();
  const {event, errors, onPublishClicked} = useSelector((state: any) => state.event);

  const setData = (value: string, element: string) => {
    if (element.includes('.')) {
      const keys = element.split('.');

      dispatch(setEvent({ ...event, [keys[0]]: {
          ...event[keys[0]],
          [keys[1]]: value
        }
      }));
    } else {
      dispatch(setEvent({ ...event, [element]: value }));
    }

    dispatch(clearErrors());
  };

  const uploadBanner = (e: any) => {
    e.forEach((image: File) => {
      dispatch(UploadBanner(event.id, image))
    })
  }

  useEffect(() => {
    if (onPublishClicked.step === "step-1") {
      dispatch(UpdateEventInformation(event.id, event))
    }
  }, [onPublishClicked])

  useEffect(() => {
    dispatch(ShowEvent(slug))
  }, []);

  return (
    <>
      <div className='flex flex-col gap-6'>
        <FormLabel label='Poster event' errorText={errors.banner} required>
          <Dropzone maxFiles={1} previewUrl={event.banner.fallback_url} onChange={uploadBanner} />
        </FormLabel>

        <div className='grid lg:grid-cols-2 gap-4'>
          <FormLabel label='Nama event' required className='flex-grow' errorText={errors.title}>
            <Input type='text' value={event.title} onChange={(e) => setData(e.target.value, 'title')} required />
          </FormLabel>

          <FormLabel label='Pilih kategori acara' required className='flex-grow' errorText={errors.type}>
            <Select value={event.type} onValueChange={(e) => setData(e, 'type')} required>
              <SelectTrigger>
                <SelectValue defaultValue='others' />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value='entertainment'>Hiburan</SelectItem>
                  <SelectItem value='education'>Edukasi</SelectItem>
                  <SelectItem value='cinema'>Cinema</SelectItem>
                  <SelectItem value='experience'>Pengalaman</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </FormLabel>
        </div>
      </div>

      <HSeparator />

      <div className='flex flex-col gap-6'>
        <p className='text-base font-medium text-danger'>Waktu kegiatan</p>

        <div className='flex justify-stretch gap-4'>
          <FormLabel label='Tanggal mulai' className='flex-grow' errorText={errors.start_at} required>
            <Input
              type='date'
              value={moment(event.start_at).format('YYYY-MM-DD')}
              onChange={(e) => setData(e.target.value, 'start_at')}
              required
            />
          </FormLabel>

          <FormLabel label='Tanggal berakhir' className='flex-grow' errorText={errors.end_at} required>
            <Input
              type='date'
              value={moment(event.end_at).format('YYYY-MM-DD')}
              onChange={(e) => setData(e.target.value, 'end_at')}
              required
            />
          </FormLabel>
        </div>
      </div>

      <HSeparator />

      <div className='flex flex-col gap-6'>
        <p className='text-base font-medium text-danger'>Lokasi kegiatan</p>

        <FormLabel label='Kegiatan online' errorText={errors['locations.type']} inline>
          <Checkbox checked={event.location?.type === 'online'} onChange={(e) => setData(e.target.checked ? 'online' : 'offline', 'location.type')} />
        </FormLabel>

        <div className='flex flex-col gap-6 lg:flex-row lg:justify-stretch lg:gap-4'>
          <FormLabel label='Tempat' className='flex-grow' errorText={errors['locations.name']} required>
            <Input type='text' value={event.location?.name} onChange={(e) => setData(e.target.value, 'location.name')} required />
          </FormLabel>

          {event.location?.type !== 'online' && <FormLabel label='Link Google Maps' className='flex-grow' errorText={errors['locations.url']} required>
            <Input type='text' value={event.location?.url} onChange={(e) => setData(e.target.value, 'location.url')} required />
          </FormLabel>}
        </div>
      </div>

      <HSeparator />

      <div className='flex flex-col gap-6'>
        <p className='text-base font-medium text-danger'>
          Deskripsi, syarat dan ketentuan
        </p>

        {/* TODO: add back RTF support */}
        {/* <RichTextInput label='Deskripsi kegiatan' required /> */}
        {/* <RichTextInput label='Syarat dan ketentuan' required /> */}

        <FormLabel label='Deskripsi kegiatan' errorText={errors.description} required>
          <Textarea onChange={(e) => setData(e.target.value, 'description')} value={event.description} required />
        </FormLabel>

        <FormLabel label='Syarat dan ketentuan' errorText={errors.terms} required>
          <Textarea onChange={(e) => setData(e.target.value, 'terms')} value={event.terms} required />
        </FormLabel>
      </div>
    </>
  );
}
