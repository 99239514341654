import { Button } from '@/components/button';
import { DataTable } from '@/components/table/datatable';
import { Tag } from '@/components/tag';
import { ThemeColor } from '@/lib/utils';
import { idrFormatter } from '@/lib/utils/currency';
import { EyeIcon } from '@heroicons/react/24/solid';
import { ColumnDef } from '@tanstack/react-table';
import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {GetTransactions} from "@/services/transactions";
import moment from "moment";
import {useLocation} from "react-router-dom";

interface Transaction {
  id: string;
  reference: string;
  booking_reference: string;
  name: string;
  date: string;
  ticket: string;
  booking_type: string;
  quantity: number;
  event: string;
  subtotal: number;
  status: {
    label: string,
    color: ThemeColor
  };
}

const columns: ColumnDef<Transaction>[] = [
  {
    header: 'Kode Booking',
    accessorKey: 'booking_reference',
  },
  {
    accessorKey: 'name',
    header: 'Nama',
  },
  {
    accessorKey: 'date',
    header: 'Tanggal',
    cell: ({ row }) => {
      const transactionDate = row.original.date;
      return moment(transactionDate).format('LL');
    },
  },
  {
    accessorKey: 'event',
    header: 'Event - Tiket',
    cell: ({row}) => {
      return <>
        {row.original.event} - <span className='text-info'>{row.original.ticket}</span>{' '}
        <span className='text-neutral-70'>(x{row.original.quantity})</span>
      </>
    }
  },
  {
    header: 'Tipe Pembelian',
    accessorKey: 'booking_type',
  },
  {
    accessorKey: 'nominal',
    header: 'Nominal',
    cell: ({ row }) => {
      const nominal = row.original.subtotal;
      return (
        <span className='text-danger'>{idrFormatter.format(nominal)}</span>
      );
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      const status = row.original.status;
      return <Tag color={status.color}>{status.label}</Tag>;
    },
  },
  {
    header: 'Aksi',
    cell: ({ row }) => {
      return (
          <Button element={'link'} to={row.original.reference} variant='neutral' size='sm' leftIcon={<EyeIcon />}>
            Detail
          </Button>
      );
    },
  },
];

export function OrgTransaction() {
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const [page, setPage] = useState(1);

  const {transactions, paginator} = useSelector((state: any) => state.transaction);

  const queryParams = new URLSearchParams(location.search);
  const searchQuery: string = queryParams.get('search') ?? '';

  useEffect(() => {
    dispatch(GetTransactions(searchQuery, page))
  }, [searchQuery, page]);

  return (
    <>
      <DataTable
        name='Riwayat Transaksi'
        noun='transaksi'
        columns={columns}
        data={transactions}
        total={paginator.to}
        currentTotal={paginator.total}
        currentPage={paginator.current_page}
        pages={paginator.last_page}
        onPageRequest={(nextPage) => setPage(nextPage)}
      />
    </>
  );
}
