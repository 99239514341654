import { DataTable } from '@/components/table/datatable';
import { Tag } from '@/components/tag';
import { ColumnDef } from '@tanstack/react-table';
import {useLocation, useParams} from "react-router-dom";
import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {GetGuests} from "@/services/guests";
import moment from "moment";

interface Attendee {
  id: string;
  number: string;
  name: string;
  date: Date;
  event_name: string;
  ticket_name: string;
  present_status: boolean;
  scans: string;
  scanned_at?: Date;
}

const columns: ColumnDef<Attendee>[] = [
  {
    accessorKey: 'number',
    header: 'No. Tiket & Nama peserta',
    cell: ({ row }) => {
      const ticketId = row.original.number;
      const name = row.original.name;

      return (
        <>
          <div>{ticketId}</div>
          <div className='text-neutral-70'>{name}</div>
        </>
      );
    },
  },
  {
    accessorKey: 'date',
    header: 'Tanggal beli tiket',
    cell: ({ row }) => {
      const purchaseDate = row.original.date;
      return (
        <>
          <span>
            {moment(purchaseDate).format('llll')}
          </span>
        </>
      );
    },
  },
  {
    accessorKey: 'event_name',
    header: 'Event - Tiket',
    cell: ({ row }) => {
      return (
        <>
          {row.original.event_name} -{' '}
          <span className='text-info'>{row.original.ticket_name}</span>
        </>
      );
    },
  },
  {
    accessorKey: 'present_status',
    header: 'Status',
    cell: ({ row }) => {
      const attended = row.original.present_status;
      return attended ? (
        <Tag color='success'>Sudah absen</Tag>
      ) : (
        <Tag color='danger'>Belum absen</Tag>
      );
    },
  },
  {
    accessorKey: 'scans',
    header: 'Riwayat Scan',
    cell: ({ row }) => {
      return (
          <>
            {row.original.scans}x
          </>
      );
    },
  },
  {
    accessorKey: 'scanned_at',
    header: 'Terakhir Kali Discan',
    cell: ({ row }) => {
      return (
        <>
          {row.original.scanned_at ? moment(row.original.scanned_at).format('lll') : '-'}
        </>
      );
    },
  },
];

export function OrgEventDetailAttendee() {
  const location = useLocation();
  const {slug} = useParams();
  const dispatch: AppDispatch = useDispatch();
  const [page, setPage] = useState(1);

  const {guests, paginator, exportRoute} = useSelector((state: any) => state.guests);

  const queryParams = new URLSearchParams(location.search);
  const searchQuery: string = queryParams.get('search') ?? '';

  useEffect(() => {
    dispatch(GetGuests(slug, searchQuery, page))
  }, [slug, searchQuery, page])

  return (
    <>
      <DataTable
        name='Daftar Peserta'
        noun='peserta'
        columns={columns}
        data={guests}
        total={paginator.to}
        currentTotal={paginator.total}
        currentPage={paginator.current_page}
        pages={paginator.last_page}
        exportRoute={exportRoute}
        onPageRequest={(nextPage) => setPage(nextPage)}
      />
    </>
  );
}
