import axios from "axios";
import {headers} from "@/services/headers"
import {ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {
    getErrors,
    loading,
    setPendingWallet,
    setPaginator,
    setSettledWallet,
    setTransactions, setWithdraw,
    setWithdrawHistories, setBanks
} from "@/reducers/financial";

export const GetTransactions = (query?: string, page = 1):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .get(process.env.REACT_APP_BASE_URL + '/org/financial/revenues?filter[search]=' + query + '&page=' + page, {
            headers: headers()
        }).then((response) => {
            dispatch(setTransactions(response.data.data));
            dispatch(setPaginator(response.data.meta));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const GetWithdraws = (query?: string, page = 1):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    return axios
        .get(process.env.REACT_APP_BASE_URL + '/org/financial/withdraws?filter[search]=' + query + '&page=' + page, {
            headers: headers()
        }).then((response) => {
            dispatch(setWithdrawHistories(response.data.data));
            dispatch(setPaginator(response.data.meta));

            dispatch(loading(false))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));

            dispatch(loading(false))
        });
};

export const StoreWithdraw = (formData: any):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    return axios
        .post(process.env.REACT_APP_BASE_URL + '/org/financial/withdraws', formData,{
            headers: headers()
        }).then((response) => {
            dispatch(setWithdraw(response.data.data));

            dispatch(loading(false))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));

            dispatch(loading(false))
        });
};

export const ShowPendingBalance = ():  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    return axios
        .get(process.env.REACT_APP_BASE_URL + '/org/financial/balances/pending', {
            headers: headers()
        }).then((response) => {
            dispatch(setPendingWallet(response.data.data));

            dispatch(loading(false))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));

            dispatch(loading(false))
        });
};

export const ShowSettledBalance = ():  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    return axios
        .get(process.env.REACT_APP_BASE_URL + '/org/financial/balances/settled', {
            headers: headers()
        }).then((response) => {
            dispatch(setSettledWallet(response.data.data));

            dispatch(loading(false))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));

            dispatch(loading(false))
        });
};

export const GetBanks = ():  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    return axios
        .get(process.env.REACT_APP_BASE_URL + '/org/financial/banks', {
            headers: headers()
        }).then((response) => {
            dispatch(setBanks(response.data.data));

            dispatch(loading(false))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));

            dispatch(loading(false))
        });
};
