import { Button } from '@/components/button';
import { DataTable } from '@/components/table/datatable';
import { Tag } from '@/components/tag';
import { idrFormatter } from '@/lib/utils/currency';
import { EyeIcon } from '@heroicons/react/24/solid';
import { ColumnDef } from '@tanstack/react-table';
import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {GetTransactionsByEvent} from "@/services/transactions";
import {ThemeColor} from "@/lib/utils";
import moment from "moment";

interface Transaction {
  id: string;
  reference: string;
  booking_reference: string;
  name: string;
  date: string;
  ticket: string;
  booking_type: string;
  quantity: number;
  event: string;
  subtotal: number;
  status: {
    label: string,
    color: ThemeColor
  };
}

const columns: ColumnDef<Transaction>[] = [
  {
    header: 'Kode Booking',
    accessorKey: 'booking_reference',
  },
  {
    header: 'Nama',
    accessorKey: 'name',
  },
  {
    header: 'Tanggal',
    cell: ({ row }) => {
      const transactionDate = row.original.date;
      return moment(transactionDate).format('LL');
    },
  },
  {
    header: 'Event - Tiket',
    cell: ({ row }) => {
      return (
        <>
          {row.original.event} -{' '}
          <span className='text-info'>{row.original.ticket}</span>{' '}
          <span className='text-neutral-70'>{`(x${row.original.quantity})`}</span>
        </>
      );
    },
  },
  {
    header: 'Tipe Pembelian',
    accessorKey: 'booking_type',
  },
  {
    accessorKey: 'nominal',
    header: 'Nominal',
    cell: ({ row }) => {
      const nominal = row.original.subtotal;
      return (
          <span className='text-danger'>{idrFormatter.format(nominal)}</span>
      );
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      const status = row.original.status;
      return <Tag color={status.color}>{status.label}</Tag>;
    },
  },
  {
    header: 'Aksi',
    cell: ({row}) => {
      return (
        <Button element={'link'} to={'/transaction/' + row.original.reference} variant='neutral' size='sm' leftIcon={<EyeIcon />}>
          Detail
        </Button>
      );
    },
  },
];

export function OrgEventDetailTransaction() {
  const location = useLocation();
  const {slug} = useParams();
  const dispatch: AppDispatch = useDispatch();

  const {transactions, paginator, exportRoute} = useSelector((state: any) => state.transaction);

  const queryParams = new URLSearchParams(location.search);
  const searchQuery: string = queryParams.get('search') ?? '';

  useEffect(() => {
    dispatch(GetTransactionsByEvent(slug, searchQuery))
  }, [slug, searchQuery])

  return (
    <>
      <DataTable exportRoute={exportRoute} name='Riwayat Transaksi' noun='transaksi' columns={columns} data={transactions} total={paginator.to} currentTotal={paginator.total} currentPage={paginator.current_page} pages={paginator.last_page} />
    </>
  );
}
