import { ArrowUpTrayIcon, TrashIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { Button } from '../button';
import toast from "react-hot-toast";

interface DropzoneProps
  extends Pick<
    DropzoneOptions,
    'accept' | 'minSize' | 'maxSize' | 'maxFiles' | 'disabled'
  > {
  onChange: (files: File[]) => void;
  onDelete?: () => void;
  previewUrl?: string
}

export const Dropzone: React.FC<DropzoneProps> = ({ onChange, onDelete, previewUrl = '', ...props  }) => {
  const defaultImages: any = previewUrl ? [previewUrl] : [];
  const [previewFileUrls, setPreviewFileUrls] = useState<string[]>(defaultImages);

  useEffect(() => {
    setPreviewFileUrls(defaultImages)
  }, [previewUrl])

  const { acceptedFiles, open, getRootProps, getInputProps } = useDropzone({
    ...props,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    maxSize: 2 * 1024 * 1024, // 2MB in bytes
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        rejectedFiles.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === 'file-too-large') {
              toast.error("File terlalu besar, maksimal 2MB");
            } else if (err.code === 'file-invalid-type') {
              toast.error("Tipe file salah, hanya boleh jpg/jpeg & png");
            }
          });
        });
      }

      if (acceptedFiles.length > 0) {
        const newUrls = acceptedFiles.map((file) => URL.createObjectURL(file));
        setPreviewFileUrls(newUrls);
        onChange(acceptedFiles);
      }
    },
  });

  const thumbs = previewFileUrls.map((url) => {
    return (
      <img
        className='h-[150px] max-w-full rounded-md object-cover'
        src={url}
        alt=''
      />
    );
  });

  const removeFile = (event: any) => {
    previewFileUrls.forEach((url) => URL.revokeObjectURL(url));

    setPreviewFileUrls([]);
    event.stopPropagation();

    if (onDelete) {
      onDelete();
    }
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => previewFileUrls.forEach((url) => URL.revokeObjectURL(url));
  }, []);

  return (
    <div
      {...getRootProps({
        className:
          'flex flex-col items-center gap-4 rounded-md border-[1px] border-neutral-40 bg-neutral-20 p-4',
      })}
    >
      <input {...getInputProps()} />
      {thumbs}
      <p className='text-xs text-neutral-70'>
        Maksimal ukuran file 2MB & Maksimal {props.maxFiles} file (Format JPG, PNG)
      </p>
      <div className='flex gap-3'>
        {previewFileUrls.length > 0 && <Button
          size='sm'
          variant='danger'
          look='outline'
          element='button'
          onClick={removeFile}
          leftIcon={<TrashIcon />}
        >
          Hapus Gambar
        </Button>}
        {previewFileUrls.length === 0 && <Button
          size='sm'
          element='button'
          onClick={(event) => {
            event.stopPropagation();
            open();
          }}
          leftIcon={<ArrowUpTrayIcon />}
        >
          Unggah gambar
        </Button>}
      </div>
    </div>
  );
};
