import { cn } from '@/lib/utils';
import {
  CalendarDaysIcon,
  EyeIcon,
  MapPinIcon,
} from '@heroicons/react/24/solid';
import React from 'react';
import { Button } from './button';
import { VSeparator } from './separator';
import { Tag } from './tag';
import moment from "moment";

export type EventCardMode = 'public' | 'private' | 'preview';

export interface EventCardProps extends React.HTMLAttributes<HTMLDivElement> {
  mode?: EventCardMode;
  data?: {
    slug: string,
    code: string,
    banner: {
      fallback_url: string,
      srcset: string,
    },
    title: string,
    type: string,
    type_label: string,
    status: {
      label: string,
      color: 'warning' | 'success' | 'danger',
    },
    state: string,
    start_at: string,
    location: {
      name: string,
      url: string,
      type: string
    }
  }
}

export const EventCard: React.FC<EventCardProps> = ({
  mode = 'public',
  className = '',
    data = undefined
}) => {
  return (
    <div
      className={cn(
        'flex shrink-0 flex-col overflow-hidden rounded-md border-[1px] border-neutral-40 shadow-md',
        className
      )}
    >
      <div className={'relative'}>
        <div className={'absolute right-0 mr-2 mt-2'}>
          {mode === 'private' && <Tag color={data?.status.color}>{data?.status.label}</Tag>}
        </div>
        <img
          className='h-[250px] w-full shrink-0 grow-0 object-fill'
          src={data?.banner.fallback_url || '/tmp/500x500.png'}
          srcSet={data?.banner.srcset}
          alt={data?.title}
        />
      </div>
      <div className='flex flex-col gap-4 p-4'>
        <div className='flex flex-wrap gap-2'>
          {data?.location?.type && <Tag color='info'>{data?.location?.type}</Tag>}
          <Tag>{data?.type_label}</Tag>
        </div>
        <h1 className='word-wrap max-line-1 h-[24px] w-full text-lg font-semibold'>
          {data?.title}
        </h1>
        <div className='flex items-center gap-2'>
          <MapPinIcon className='h-[16px] shrink-0 grow-0 text-neutral-60' />
          <span className='max-line-1 h-[20px] w-[calc(80%)] shrink-0 text-sm font-medium'>
            {data?.location?.name}
          </span>
        </div>
        <div className='flex flex-wrap gap-2'>
          <div className='flex items-center gap-2'>
            <CalendarDaysIcon className='h-[16px] text-neutral-60' />
            <span className='text-sm font-medium'>{moment(data?.start_at).format('ll')}</span>
          </div>
        </div>
        <Button
          element='link'
          to={mode === 'private' ? `/event/${data?.code}/info` : '#'}
          leftIcon={<EyeIcon />}
        >
          Lihat detail
        </Button>
      </div>
    </div>
  );
};
