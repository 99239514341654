import { Alert } from '@/components/alert';
import { Button } from '@/components/button';
import { CheckIcon } from '@heroicons/react/24/outline';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { SectionItem, SectionTabs } from '../../_nav/section';
import {useState} from "react";

const sections: Record<string, string> = {
  info: 'Informasi acara',
  // ots: 'Sistem OTS',
  ticket: 'Daftar tiket',
  transaction: 'Daftar transaksi',
  // committee: 'Daftar panitia',
  attendee: 'Daftar peserta',
};

export function OrgEventDetailLayout() {
  const location = useLocation();

  const pathArray = location.pathname.split('/').slice(3);
  const section = pathArray[0];

  return !section ? (
    <Navigate to='info' />
  ) : (
    <>
      <SectionTabs>
        {Object.entries(sections).map(([key, value]) => {
          return (
            <SectionItem
              id={key}
              key={key}
              name={value}
              isCurrent={section === key}
            />
          );
        })}
      </SectionTabs>
      <Outlet />
    </>
  );
}
