import {TicketList} from "@/routes/org/event/_ticket/tickets";
import {useEffect} from "react";
import {GetTickets} from "@/services/tickets";
import {useParams} from "react-router-dom";
import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";

export function OrgTicketList() {
  const {slug} = useParams();
  const dispatch: AppDispatch = useDispatch();

  const {tickets} = useSelector((state: any) => state.tickets);

  useEffect(() => {
    dispatch(GetTickets(slug))
  }, [slug])

  return (<>
    <TicketList
        mode={'info'}
        data={tickets}
    title={
      <h1 className='text-lg font-medium sm:flex-grow'>Daftar Tiket</h1>
    }/>
  </>);
}
