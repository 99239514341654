import React, {useEffect, useState} from "react";
import {Input} from "@/components/form/input";
import {FormLabel} from "@/components/form/formlabel";
import {Button} from '@/components/button';
import {CheckIcon} from "@heroicons/react/24/solid";
import {useLocation} from "react-router-dom";
import {clearErrors} from "@/reducers/auth";
import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";
import {UpdatePassword} from "@/services/auth";

export function ResetPassword() {
    const dispatch: AppDispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const {errors, isSuccess, successMsg} = useSelector((state: any) => state.auth);

    const [formData, setFormData] = useState({
        email: queryParams.get('email'),
        token: queryParams.get('token'),
        password: '',
        password_confirmation: '',
    })

    const setData = (e: any, element: string) => {
        setFormData({...formData, [element]: e.target.value});

        dispatch(clearErrors());
    }

    const submit = (e: any) => {
        e.preventDefault();

        dispatch(UpdatePassword(formData))
    }

    useEffect(() => {
        if(isSuccess) {
            window.location.href = "/auth/login";
        }
    }, [isSuccess])

    return (
        <>
            <div className="text-900 text-3xl font-medium mb-1">Reset password? 🔑</div>
            <p className={"text-400 mb-4"}>Ganti passwordmu secara berkala untuk keamanan</p>
            <form onSubmit={submit}>
                <div>
                    <div className="mb-3">
                        <FormLabel label='Password Baru' successText={successMsg} errorText={errors.password} required>
                            <Input type='password' value={formData.password} onChange={(e) => setData(e, 'password')} required/>
                        </FormLabel>
                    </div>
                    <div className="mb-3">
                        <FormLabel label='Konfirmasi Password Baru' required>
                            <Input type='password' value={formData.password_confirmation} onChange={(e) => setData(e, 'password_confirmation')} required/>
                        </FormLabel>
                    </div>
                    <div>
                        <Button
                            className={'w-full'}
                            color='neutral'
                            leftIcon={<CheckIcon/>}
                        >Reset Password</Button>
                    </div>
                </div>
            </form>
        </>
    )
}
