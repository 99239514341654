import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    withdraws: [],
    withdraw: null,
    pendingWallet: {
        balance: 0
    },
    settledWallet: {
        balance: 0
    },
    transactions: [],
    banks: [],
    paginator: [],
    loading: false,
    errorMsg: '',
    errors: {}
};

const financialSlice = createSlice({
    name: 'financial',
    initialState,
    reducers: {
        setWithdrawHistories: (state, action) => {
            state.withdraws = action.payload;
        },
        setTransactions: (state, action) => {
            state.transactions = action.payload;
        },
        setWithdraw: (state, action) => {
            state.withdraw = action.payload;
        },
        setPendingWallet: (state, action) => {
          state.pendingWallet = action.payload;
        },
        setSettledWallet: (state, action) => {
            state.settledWallet = action.payload;
        },
        setBanks: (state, action) => {
            state.banks = action.payload;
        },
        setPaginator: (state, action) => {
            state.paginator = action.payload;
        },
        getErrors: (state, action) => {
            state.errorMsg = action.payload.errorMsg;
            state.errors = action.payload.errors;
        },
        clearErrors: (state) => {
            state.errorMsg = '';
            state.errors = '';
        },
        loading: (state, action) => {
            state.loading = action.payload;
        }
    },
});

export const { loading, setPendingWallet, setPaginator, setBanks, setSettledWallet, setWithdraw, setWithdrawHistories, setTransactions, clearErrors, getErrors } = financialSlice.actions;
export default financialSlice.reducer;
