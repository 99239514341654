import axios from "axios";
import {headers} from "@/services/headers"
import {ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {loading, getErrors, setDocument} from "@/reducers/events";
import {setOnSuccessSubmit} from "@/reducers/events";

export const UpdateDocumentInformation = (eventId: string, attributes: any):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .post(process.env.REACT_APP_BASE_URL + '/org/events/'+ eventId +'/documents',
            attributes,
            {
                headers: {...headers(), "Content-Type": "multipart/form-data"}
        }).then((response) => {
            dispatch(setOnSuccessSubmit('document-updated'))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const UploadDocumentIdentity = (eventId: string, attributes: File):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))
console.log(attributes)
    const response = axios
        .post(process.env.REACT_APP_BASE_URL + '/org/events/'+ eventId +'/documents/ktp',
            {identity: attributes},
            {
                headers: {...headers(), "Content-Type": "multipart/form-data"}
        }).then((response) => {
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const UploadDocumentPermit = (eventId: string, attributes: File):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .post(process.env.REACT_APP_BASE_URL + '/org/events/'+ eventId +'/documents/permit',
            {permit: attributes},
            {
                headers: {...headers(), "Content-Type": "multipart/form-data"}
        }).then((response) => {
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const DeleteDocument = (eventId: string, documentId: string):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .delete(process.env.REACT_APP_BASE_URL + '/org/events/'+ eventId +'/documents/' + documentId,
            {
                headers: {...headers(), "Content-Type": "multipart/form-data"}
        }).then((response) => {
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const GetDocumentInformation = (slug?: string):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .get(process.env.REACT_APP_BASE_URL + '/org/events/'+ slug +'/documents',
            {
                headers: {...headers(), "Content-Type": "multipart/form-data"}
        }).then((response) => {
            dispatch(setDocument(response.data.data))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};
