import { cn } from '@/lib/utils';
import { OmitNullUndefined } from '@/lib/utils/types';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import styles from './button.module.css';
import {SpinnerIcon} from "primereact/icons/spinner";

export const buttonVariants = cva(
  [
    'inline-flex',
    'border-[1px]',
    'items-center',
    'justify-center',
    'whitespace-nowrap',
    'rounded-md',
    'font-medium',
    'gap-1',
    styles.btn,
  ],
  {
    variants: {
      variant: {
        neutral: '',
        primary: '',
        danger: '',
        warning: '',
        success: '',
        info: '',
        disabled: 'bg-neutral-40 border-neutral-40 text-neutral-60',
      },
      size: {
        sm: 'px-[8px] py-[6px] text-xs',
        md: 'px-[12px] py-[8px] text-sm',
        lg: 'px-[20px] py-[12px] text-base',
      },
      look: {
        fill: '',
        outline: '',
        flat: '',
      },
    },
    compoundVariants: [
      // Pressed shadow
      {
        variant: 'neutral',
        className: styles.btnNeutral,
      },
      {
        variant: 'primary',
        className: styles.btnPrimary,
      },
      {
        variant: 'danger',
        className: styles.btnDanger,
      },
      {
        variant: 'warning',
        className: styles.btnWarning,
      },
      {
        variant: 'success',
        className: styles.btnSuccess,
      },
      {
        variant: 'info',
        className: styles.btnInfo,
      },

      // Fill
      {
        variant: 'neutral',
        look: 'fill',
        className: 'bg-neutral-10',
      },
      {
        variant: 'primary',
        look: 'fill',
        className:
          'bg-primary border-neutral-100 hover:text-neutral-10 active:text-neutral-10 hover:bg-primary-hover active:bg-primary-pressed',
      },
      {
        variant: 'danger',
        look: 'fill',
        className:
          'bg-danger border-danger text-neutral-10 active:border-danger-pressed hover:bg-danger-hover active:bg-danger-pressed',
      },
      {
        variant: 'warning',
        look: 'fill',
        className:
          'bg-warning border-warning text-neutral-10 active:border-warning-pressed hover:bg-warning-hover active:bg-warning-pressed',
      },
      {
        variant: 'success',
        look: 'fill',
        className:
          'bg-success border-success text-neutral-10 active:border-success-pressed hover:bg-success-hover active:bg-success-pressed',
      },
      {
        variant: 'info',
        look: 'fill',
        className:
          'bg-info border-info text-neutral-10 active:border-info-pressed hover:bg-info-hover active:bg-info-pressed',
      },

      // Outline & Flat
      {
        look: 'outline',
        className: 'bg-neutral-10',
      },
      {
        variant: 'neutral',
        look: ['fill', 'outline', 'flat'], // Special case for neutral
        className:
          'hover:border-neutral-40 text-neutral-100 active:border-neutral-30 hover:bg-neutral-30 active:bg-neutral-30',
      },
      {
        variant: 'primary',
        look: ['outline', 'flat'],
        className:
          'hover:border-primary-border text-neutral-100 hover:bg-primary-surface hover:text-primary active:text-primary-pressed',
      },
      {
        variant: 'danger',
        look: ['outline', 'flat'],
        className:
          'hover:border-danger-border text-danger hover:bg-danger-surface active:text-danger-pressed',
      },
      {
        variant: 'warning',
        look: ['outline', 'flat'],
        className:
          'hover:border-warning-border text-warning hover:bg-warning-surface active:text-warning-pressed',
      },
      {
        variant: 'success',
        look: ['outline', 'flat'],
        className:
          'hover:border-success-border text-success hover:bg-success-surface active:text-success-pressed',
      },
      {
        variant: 'info',
        look: ['outline', 'flat'],
        className:
          'hover:border-info-border text-info hover:bg-info-surface active:text-info-pressed',
      },

      // Outline
      {
        variant: 'neutral',
        look: 'outline',
        className: 'border-neutral-40',
      },
      {
        variant: 'primary',
        look: 'outline',
        className: 'border-primary-border',
      },
      {
        variant: 'danger',
        look: 'outline',
        className: 'border-danger-border',
      },
      {
        variant: 'warning',
        look: 'outline',
        className: 'border-warning-border',
      },
      {
        variant: 'success',
        look: 'outline',
        className: 'border-success-border',
      },
      {
        variant: 'info',
        look: 'outline',
        className: 'border-info-border',
      },

      // Flat
      {
        look: 'flat',
        className: 'border-transparent',
      },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'md',
      look: 'fill',
    },
  }
);

export type ButtonSize = OmitNullUndefined<
  VariantProps<typeof buttonVariants>['size']
>;

const iconSizeStyles: Record<ButtonSize, string> = {
  sm: 'h-[12px]',
  md: 'h-[14px]',
  lg: 'h-[16px]',
};

const justifyStyles: Record<'start' | 'center' | 'end', string> = {
  start: 'justify-start',
  center: 'justify-center',
  end: 'justify-end',
};

interface ButtonPropsBase extends VariantProps<typeof buttonVariants> {
  // asChild?: boolean;
  loading?: boolean,
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
}

export type ButtonButtonElementProps =
  React.ButtonHTMLAttributes<HTMLButtonElement> & { element?: 'button' };
export type ButtonAnchorElementProps =
  React.AnchorHTMLAttributes<HTMLAnchorElement> & { element?: 'a' };
export type ButtonLinkElementProps = LinkProps & { element?: 'link' };

export type ButtonProps = ButtonPropsBase &
  (
    | ButtonButtonElementProps
    | ButtonAnchorElementProps
    | ButtonLinkElementProps
  );

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    className,
    variant,
    size = 'md',
    look,
      loading = false,
    // asChild = false,
    leftIcon,
    rightIcon,
    element = 'button',
    children,
    ...filteredProps
  } = props;

  const inner = (
    <>
      {leftIcon ? (
        <Slot
          className={cn(iconSizeStyles[size ?? 'md'], leftIcon.props.className)}
        >
          {loading ? <SpinnerIcon className={'spinner'} /> : leftIcon}
        </Slot>
      ) : undefined}

      {children ? <span>{children}</span> : undefined}

      {rightIcon ? (
        <Slot
          className={cn(
            iconSizeStyles[size ?? 'md'],
            rightIcon.props.className
          )}
        >
          {rightIcon}
        </Slot>
      ) : undefined}
    </>
  );

  // const Comp = asChild ? Slot : 'button';
  const modVariant =
    element === 'button'
      ? (props as ButtonButtonElementProps).disabled
        ? 'disabled'
        : variant
      : variant;

  const additionalProps = {
    className: cn(
      buttonVariants({ variant: modVariant, size, look, className })
    ),
  };

  return element === 'a' ? (
    <a {...(filteredProps as ButtonAnchorElementProps)} {...additionalProps}>
      {inner}
    </a>
  ) : element === 'link' ? (
    <Link {...(filteredProps as ButtonLinkElementProps)} {...additionalProps}>
      {inner}
    </Link>
  ) : element === 'button' ? (
    <button
      {...(filteredProps as ButtonButtonElementProps)}
      {...additionalProps}
    >
      {inner}
    </button>
  ) : null;
};
Button.displayName = 'Button';
