import { Button } from '@/components/button';
import { HSeparator, VSeparator } from '@/components/separator';
import { Tag } from '@/components/tag';
import { cn } from '@/lib/utils';
import {
  CalendarDaysIcon,
  ClockIcon,
  EyeIcon,
  MapPinIcon,
} from '@heroicons/react/24/solid';
import React from 'react';

import styles from './DashboardEventCard.module.css';
import moment from "moment";

export interface DashboardEventCardProps
  extends React.HTMLAttributes<HTMLDivElement> {
  showButton?: boolean;
  data?: {
    slug: string,
    code: string,
    banner: {
      fallback_url: string,
      srcset: string,
    },
    title: string,
    type: string,
    status: string,
    start_at: string,
    location: {
      name: string,
      url: string,
      type: string
    }
  }
}

export const DashboardEventCard: React.FC<DashboardEventCardProps> = ({
  showButton = true,
    data
}) => {
  const buttons = showButton ? (
    <>
      <Button
        size='sm'
        variant='neutral'
        leftIcon={<EyeIcon />}
        className='flex-grow'
        element='link'
        to={`../event/${data?.code}/attendee`}
      >
        Lihat peserta
      </Button>
      <Button size='sm' leftIcon={<EyeIcon />} className='flex-grow' element='link'
              to={`../event/${data?.code}/transaction`}>
        Lihat penjualan
      </Button>
    </>
  ) : undefined;

  return (
    <div
      className={cn(
        styles.cardWrapper,
        'flex-shrink-0 gap-4 overflow-hidden rounded-md border-[1px] border-info-border bg-info-surface p-4 sm:w-[568px] sm:gap-0 sm:border-neutral-40 sm:bg-neutral-10 sm:p-0 sm:shadow-md'
      )}
    >
      <img
        className={cn(
          styles.imageArea,
          'h-[154px] w-full rounded-md object-cover sm:h-full sm:rounded-none'
        )}
        src={data?.banner.fallback_url}
        srcSet={data?.banner.srcset}
        alt={data?.title}
      />
      <div
        className={cn(styles.infoArea, 'flex flex-col gap-2 sm:gap-4 sm:p-4')}
      >
        <div className='flex flex-col items-start gap-2 sm:flex-row sm:flex-wrap'>
          <Tag color='info'>{data?.location.type}</Tag>
          <Tag>{data?.type}</Tag>
        </div>
        <h1 className='max-line-2 h-[24px] flex-shrink-0 overflow-hidden text-base font-medium sm:h-[60px] sm:text-xl sm:font-semibold'>
          {data?.title}
        </h1>
        <div className='flex flex-col gap-1 sm:gap-4'>
          <div className='flex items-center gap-2'>
            <MapPinIcon className='h-[16px] shrink-0 grow-0 text-neutral-60' />
            <span className='max-line-1 h-[18px] w-[calc(80%)] shrink-0 text-xs font-medium sm:text-sm'>
              {data?.location.name}
            </span>
          </div>
          <div className='flex flex-col gap-1 sm:flex-row sm:gap-2'>
            <div className='flex items-center gap-2'>
              <CalendarDaysIcon className='h-[16px] text-neutral-60' />
              <span className='text-xs font-medium sm:text-sm'>
                {moment(data?.start_at).format('ll')}
              </span>
            </div>
            <VSeparator className='hidden sm:block' />
            <div className='flex items-center gap-2'>
              <ClockIcon className='h-[16px] text-neutral-60' />
              <span className='text-xs font-medium sm:text-sm'>{moment(data?.start_at).format('LT')}</span>
            </div>
          </div>

          {buttons ? (
            <>
              <HSeparator className='hidden gap-2 sm:flex' />
              <div className='hidden gap-2 sm:flex'>{buttons}</div>
            </>
          ) : undefined}
        </div>
      </div>
      {buttons ? (
        <div
          className={cn(styles.buttonsArea, 'flex flex-col gap-2 sm:hidden')}
        >
          {buttons}
        </div>
      ) : undefined}
    </div>
  );
};
DashboardEventCard.displayName = 'DashboardEventCard';
