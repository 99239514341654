import { Alert } from '@/components/alert';
import { Button } from '@/components/button';
import { HSeparator } from '@/components/separator';
import { NamedTable } from '@/components/table/namedtable';
import { Tag } from '@/components/tag';
import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  EyeIcon,
} from '@heroicons/react/24/solid';
import { DashboardCard } from './_card';
import { DashboardEventCard } from './_eventcard';
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {AppDispatch} from "@/root/store";
import {GetRunningEvents, GetStats, GetTransactions, GetVisitors} from "@/services/dashboard";
import moment from "moment";
import {FormatCurrency} from "@/routes/root";

export function OrgDashboard() {
  const dispatch: AppDispatch = useDispatch();
  const { events, stats, visitors, transactions } = useSelector((state: any) => state.dashboard);

  useEffect(() => {
    dispatch(GetRunningEvents())
    dispatch(GetVisitors())
    dispatch(GetTransactions())
    dispatch(GetStats())
  }, [dispatch]);

  return (
    <>
      {/*<Alert color='warning'>
        <ExclamationCircleIcon className='h-5 w-5 flex-shrink-0 text-warning' />
        <p className='h-fit flex-grow text-sm font-medium'>
          Profil Anda belum lengkap! Harap lengkapi profil terlebih dahulu.
        </p>
        <Button
          className='flex-shrink-0'
          variant='warning'
          size='sm'
          element={'link'}
          to={'/setting'}
          leftIcon={<PencilSquareIcon />}
        >
          Lengkapi profil
        </Button>
      </Alert>*/}

      {
        events.length > 0 &&
      <div>
        <h1 className='mb-3 text-lg font-medium'>Event yang sedang berjalan</h1>
        <div className='flex flex-col gap-6 pb-2 sm:flex-row sm:overflow-x-auto'>
          {
            events.map((event: any, key: string) => (
              <DashboardEventCard data={event} key={key} />
            ))
          }
        </div>
      </div>
      }

      <HSeparator />

      <div className='grid grid-cols-2 gap-2 pb-2 sm:gap-6 md:grid-cols-3 xl:grid-cols-4'>
        <DashboardCard
          variant='yellow'
          name='Event'
          topLabel={{ primary: stats.total_events, secondary: 'event' }}
        />
        <DashboardCard
          variant='blue'
          name='Pemasukan'
          topLabel={{ primary: <FormatCurrency amount={stats.total_transactions_in_rupiah} /> }}
          bottomLabel={{
            primary: stats.transactions_rp_percent_date_to_date + '%',
            secondary: 'daripada kemarin'
          }}
        />
        <DashboardCard
          variant='white'
          name='Transaksi'
          topLabel={{ primary: stats.total_transactions, secondary: 'transaksi' }}
          bottomLabel={{
            primary: stats.transactions_date_to_date,
            secondary: 'daripada kemarin'
          }}
        />
        <DashboardCard
          variant='whiteAlt'
          name='Pengunjung'
          topLabel={{ primary: stats.total_visitors, secondary: 'pengunjung' }}
          bottomLabel={{
            primary: stats.visitors_date_to_date,
            secondary: 'daripada kemarin'
          }}
        />
      </div>

      <HSeparator />

      <div className='flex flex-col gap-6 lg:flex-row lg:items-start'>
        <NamedTable
          title='Riwayat Penjualan'
          ctaButton={
            <Button
              variant='neutral'
              size='sm'
              leftIcon={<EyeIcon />}
              element='link'
              to='../transaction'
            >
              Lihat semua
            </Button>
          }
          className='flex-grow'
        >
          <thead>
            <tr>
              <th>Nama</th>
              <th>Tanggal</th>
              <th>Event - Tiket</th>
              <th>Nominal transaksi</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
          {
              transactions.map((transaction: any, key: string) => (
                <tr key={key}>
                  <td>{transaction.name}</td>
                  <td>{moment(transaction.date).format('LL')}</td>
                  <td>
                      {transaction.event} - <span className='text-info'>{transaction.ticket}</span>{' '}
                    <span className='text-neutral-70'>(x{transaction.quantity})</span>
                  </td>
                  <td className='text-danger'>
                      <FormatCurrency amount={transaction.subtotal} currency={transaction.currency_code} />
                  </td>
                  <td>
                    <Tag color={transaction.status.color}>{transaction.status.label}</Tag>
                  </td>
                </tr>
              ))
          }
          </tbody>
        </NamedTable>

        <HSeparator className='block lg:hidden' />

        <NamedTable
          title='Riwayat Pengunjung'
          ctaButton={undefined}
          className='lg:basis-[250px]'
        >
          <thead>
            <tr>
              <th>Tanggal</th>
              <th>Jumlah</th>
            </tr>
          </thead>
          <tbody>
          { visitors.map((visitor: any, key: string) => (
              <tr key={key}>
                <td>{visitor.date}</td>
                <td>{visitor.quantity}</td>
              </tr>
            ))
          }
          </tbody>
        </NamedTable>
      </div>
    </>
  );
}
