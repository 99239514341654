import {DataTable} from "@/components/table/datatable";
import {useLocation} from "react-router-dom";
import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {GetTransactions} from "@/services/financial";
import {ColumnDef} from "@tanstack/react-table";
import moment from "moment";
import {idrFormatter} from "@/lib/utils/currency";
import {Tag} from "@/components/tag";

interface Transaction {
  id: string;
  reference: string;
  name: string;
  email: string;
  phone: string;
  date: string;
  deposited_at: string;
  ticket: string;
  quantity: number;
  event: string;
  channel: {
    code: string
  };
  status: {
    color: 'success' | 'danger' | 'warning',
    label: string
  };
  total: number;
}

const columns: ColumnDef<Transaction>[] = [
  {
    accessorKey: 'name',
    header: 'Pembeli',
    cell: ({row}) => {
      return(
          <>
              <div>{row.original.name}</div>
              <div className='font-normal text-neutral-70'>
                {row.original.email}
              </div>
          </>
      )
    }
  },
  {
    accessorKey: 'date',
    header: 'Tanggal Transaksi',
    cell: ({ row }) => {
      const transactionDate = row.original.date;
      return moment(transactionDate).format('ll');
    },
  },
  {
    accessorKey: 'event',
    header: 'Event - Tiket',
    cell: ({row}) => {
      return <>
        {row.original.event} - <span className='text-info'>{row.original.ticket}</span>{' '}
        <span className='text-neutral-70'>(x{row.original.quantity})</span>
      </>
    }
  },
  {
    accessorKey: 'channel',
    header: 'Metode Pembayaran',
    cell: ({row}) => {
      return <>
        <img
            src={`/assets/channels/${row.original.channel.code.toLowerCase()}.png`}
            className='w-20 h-full rounded-md'
            alt={row.original.channel.code}
        />
      </>
    }
  },
  {
    accessorKey: 'nominal',
    header: 'Nominal',
    cell: ({ row }) => {
      const nominal = row.original.total;
      return (
          <span className='text-danger'>{idrFormatter.format(nominal)}</span>
      );
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      const status = row.original.status;
      return (
          <Tag color={status.color}>{status.label}</Tag>
      );
    },
  },
  {
    accessorKey: 'date',
    header: 'Dapat Ditarik Setelah',
    cell: ({ row }) => {
      const settleDate = row.original.deposited_at;
      return moment(settleDate).format('ll');
    },
  }
];

export function OrgCommercePayment() {
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const [page, setPage] = useState(1);

  const {transactions, paginator} = useSelector((state: any) => state.financial);

  const queryParams = new URLSearchParams(location.search);
  const searchQuery: string = queryParams.get('search') ?? '';

  useEffect(() => {
    dispatch(GetTransactions(searchQuery, page))
  }, [searchQuery, page]);

  return (
    <>
      <DataTable
          name='Riwayat Pemasukan'
          noun='transaksi'
          columns={columns}
          data={transactions}
          total={paginator.to}
          currentTotal={paginator.total}
          currentPage={paginator.current_page}
          pages={paginator.last_page}
          onPageRequest={(nextPage) => setPage(nextPage)}
      />
    </>
  );
}
