import {createSlice} from '@reduxjs/toolkit';

interface TransactionInterface {
    id?: number | null;
    reference?: string | null;
    buyer?: {
        id?: number | null;
        name?: string | null;
        email?: string | null;
        whatsapp?: string | null;
    } | null;
    date?: string | null;
    event?: {
        id?: number | null;
        code?: string | null;
        slug?: string | null;
        title?: string | null;
        banner?: {
            fallback_url?: string | null;
            srcset?: string | null;
        } | null;
        type?: string | null;
        status?: string | null;
        state?: string | null;
        start_at?: string | null;
        end_at?: string | null;
        location?: {
            name?: string | null;
            url?: string | null;
            type?: string | null;
        } | null;
    } | null;
    ticket?: string | null;
    quantity?: string | null;
    subtotal?: string | null;
    channel?: string | null;
    channel_name?: string | null;
    currency_code?: string | null;
    status?: {
        label?: string | null;
        color?: string | null;
    } | null;
}


const initialTransaction: TransactionInterface = {
    id: null,
    reference: null,
    buyer: {
        id: null,
        name: null,
        email: null,
        whatsapp: null,
    },
    date: null,
    event: {
        id: null,
        code: null,
        slug: null,
        title: null,
        banner: {
            fallback_url: null,
            srcset: null,
        },
        type: null,
        status: null,
        state: null,
        start_at: null,
        end_at: null,
        location: {
            name: null,
            url: null,
            type: null,
        },
    },
    ticket: null,
    quantity: null,
    subtotal: null,
    channel: null,
    channel_name: null,
    currency_code: undefined,
    status: {
        label: null,
        color: null,
    },
}

const initialState = {
    transactions: [],
    paginator: [],
    transaction: initialTransaction,
    exportRoute: '',
    loading: false,
    errorMsg: '',
    errors: {}
};

const transactionSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        transactions: (state, action) => {
            state.transactions = action.payload;
        },
        setPaginator: (state, action) => {
            state.paginator = action.payload;
        },
        setExportRoute: (state, action) => {
            state.exportRoute = action.payload;
        },
        transaction: (state, action) => {
            state.transaction = action.payload;
        },
        getErrors: (state, action) => {
            state.errorMsg = action.payload.errorMsg;
            state.errors = action.payload.errors;
        },
        clearErrors: (state) => {
            state.errorMsg = '';
            state.errors = '';
        },
        loading: (state, action) => {
            state.loading = action.payload;
        }
    },
});

export const { loading, transactions, setPaginator, setExportRoute, transaction, clearErrors, getErrors } = transactionSlice.actions;
export default transactionSlice.reducer;
