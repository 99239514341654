import {createSlice, ThunkAction} from '@reduxjs/toolkit';
import {
    SendLinkResetPasswordService
} from '@/services/auth';
import { RootState} from "../store";

const initialState = {
    successMsg: '',
    loading: false,
    errorMsg: '',
    errors: {}
};

const passwordSlice = createSlice({
    name: 'passwords',
    initialState,
    reducers: {
        sendLinkResetPasswordSuccess: (state, action) => {
            state.successMsg = action.payload.message;
        },
        sendLinkResetPasswordFailure: (state, action) => {
            state.errorMsg = action.payload.errorMsg;
            state.errors = action.payload.errors;
        },
        clearText: (state) => {
            state.errorMsg = '';
            state.errors = '';
            state.successMsg = '';
        },
        loading: (state, action) => {
            state.loading = action.payload;
        }
    },
});

export const { sendLinkResetPasswordSuccess, sendLinkResetPasswordFailure, clearText, loading } = passwordSlice.actions;
export default passwordSlice.reducer;

export const sendLinkResetPassword = (email: string): ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    try {
        dispatch(loading(true))

        const response = await SendLinkResetPasswordService(email);

        dispatch(sendLinkResetPasswordSuccess(response.data));
    } catch (error: any) {
        const {errors, message} = error.response.data.error;

        dispatch(sendLinkResetPasswordFailure({
            errorMsg: message,
            errors: errors,
        }));
    }

    dispatch(loading(false))
};
