import appStoreLogo from "@/routes/org/qrcode/_assets/appstore.png";
import playStoreLogo from "@/routes/org/qrcode/_assets/playstore.png";
import {Scanner} from '@yudiel/react-qr-scanner';
import React, {useEffect} from "react";
import {Modal} from "@/components/modal";
import {
    HomeIcon,
    QrCodeIcon,
    TicketIcon
} from "@heroicons/react/24/solid";
import {Button} from "@/components/button";
import moment from "moment";
import {HSeparator} from "@/components/separator";
import {ScanTicket} from "@/services/events";
import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";
import {setTicketScanned, getErrors} from "@/reducers/events";

const Section: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
                                                                     children,
                                                                 }) => {
    return <div className='flex flex-col gap-4 mb-4'>{children}</div>;
};

const Row: React.FC<{ label: React.ReactNode; value: React.ReactNode }> = ({
                                                                               label,
                                                                               value,
                                                                           }) => {
    return (
        <div className='flex justify-between gap-2 text-sm sm:flex-row'>
            <span className='font-medium text-neutral-70 sm:flex-grow'>{label}</span>
            {value}
        </div>
    );
};

const DetailTicketComponent = (data: any) => {
    const ticket = data.data;

    return (
        <>
            <Section>
                <Row
                    label='Kode Booking'
                    value={<span className='text-info'>{ticket.booking_code}</span>}
                />
                <Row
                    label='No. tiket'
                    value={<span className='text-info'>{ticket.number}</span>}
                />
                <Row label='Nama pembeli' value={ticket.guest?.name}/>
                <Row label='No. Hp' value={ticket.guest?.whatsapp}/>
                <Row label='Email' value={ticket.guest?.email}/>
            </Section>
            <HSeparator/>
            <div className='my-4 flex flex-col gap-3 sm:flex-row'>
                <div className='flex flex-grow flex-col gap-3'>
                    <h1 className='text-xl font-semibold sm:min-h-[60px]'>
                        {ticket.event}
                    </h1>
                    <div className='flex-col gap-2'>
                        <div className='flex flex-grow flex-col gap-2 mb-2'>
                            <p className='text-sm font-medium'>Tiket terpilih</p>
                            <div className='flex gap-3 rounded-md border-[1px] border-neutral-40 p-3'>
                                <TicketIcon className='h-[20px] w-[20px] text-primary'/>
                                <p className='text-sm font-medium'>{ticket.ticket?.name}</p>
                            </div>
                        </div>
                        <div className='flex flex-col gap-2 mb-2'>
                            <p className='text-sm font-medium'>Terakhir kali discan</p>
                            <div className='rounded-md border-[1px] border-neutral-40 p-3'>
                                <p className='text-sm font-medium'>{moment(ticket.last_scanned_at).format('llll')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <HSeparator/>
            <div className='mt-4 flex gap-3 flex-col'>
                <h1 className='text-xl font-semibold'>
                    Riwayat scan
                </h1>
                <Section>
                        <ul>
                            { ticket.histories.map((item: any, index: string) => (
                            <li className="mb-2" key={index}>
                                <div className="flex gap-2">
                                    <span className="flex-shrink-0 overflow-hidden rounded-full p-2 text-gray-900">
                                      <QrCodeIcon className={'w-4 h-4'}/>
                                    </span>
                                    <h5 className="font-sans text-lg tracking-normal">
                                        {moment(item.scanned_at).format('llll')}
                                    </h5>
                                </div>
                            </li>
                            ))}
                        </ul>

                </Section>
            </div>
        </>
    )
}

const FailedScanComponent = () => {
    return (
        <div className='flex flex-col items-center gap-6 p-6'>
            <img
                src='/assets/empty.png'
                width={300}
                height={247}
                alt=''
            />
            <div className='flex flex-col items-center gap-3'>
                <p className='text-2xl font-medium'>QR Code tidak terdeteksi</p>
                <p className='text-center text-base text-neutral-70'>
                    Tiket tidak ditemukan, harap scan QR Code yang lain.
                </p>
            </div>
        </div>
    )
}


export function QrCodeDashboard() {
    const dispatch: AppDispatch = useDispatch();
    const [showModal, setShowModal] = React.useState(false);

    const {ticket, errors} = useSelector((state: any) => state.event);

    const showTicket = (qrCode: string) => {
        dispatch(ScanTicket(qrCode));
    }

    useEffect(() => {
        if (Object.keys(ticket).length !== 0 || errors?.qr_code) {
            setShowModal(true)
        }
    }, [ticket, errors])

    return (
        <>
            <Modal
                show={showModal}
                onRequestClose={() => setShowModal(false)}
                width='medium'
                title={ticket.id ? ticket.number : 'QR Code Tidak Valid'}
                body={
                    ticket.id ? <DetailTicketComponent data={ticket}/> :
                        <FailedScanComponent/>
                }
                footer={
                    <div className='flex flex-col gap-3'>
                        <Button
                            className={'w-full'}
                            onClick={() => {
                                dispatch(setTicketScanned({}))
                                dispatch(getErrors({}))
                                setShowModal(false)
                            }}
                            leftIcon={<QrCodeIcon/>}
                        >
                            Scan QR lain
                        </Button>
                        <Button
                            className={'w-full'}
                            variant='neutral'
                            element={'a'}
                            href={'/dashboard'}
                            leftIcon={<HomeIcon/>}
                        >
                            Kembali ke Beranda
                        </Button>
                    </div>
                }
            />
            <Scanner
                onScan={(result) => {
                    if(result[0].format !== "unknown") {
                        showTicket(result[0]?.rawValue);
                    }
                }}
                paused={showModal}
                constraints={{
                    facingMode: 'environment'
                }}
                components={{
                    torch: true,
                    finder: false
                }}
                styles={{
                    video: {
                        objectFit: 'cover',
                        height: '100%'
                    },
                }}
            />
            {!showModal && <div className="absolute inset-0">
                <div className="relative w-full h-full">
                    {/* Line running up and down */}
                    <div className="absolute w-full h-[2px] bg-green-500 animate-scanning-line"></div>
                </div>
            </div>}
        </>
    );
}
