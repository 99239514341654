
import { Button } from '@/components/button';
import { HSeparator } from '@/components/separator';
import { cn } from '@/lib/utils';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { DocumentCheckIcon, DocumentTextIcon } from '@heroicons/react/24/solid';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "@/root/store";
import {setOnPublishClicked} from "@/reducers/events";
import React, {useEffect} from "react";
import moment from "moment";
import toast from "react-hot-toast";

const StepIndicator: React.FC<{ label: string; current: boolean }> = ({
  label,
  current,
}) => {
  return (
    <div
      className={`max-line-1 min-w-0 flex-shrink flex-grow ${
        current ? 'basis-10' : 'basis-0'
      }`}
    >
      <p
        className={`max-line-1 mb-2 h-[24px] text-base ${
          current
            ? 'font-medium text-neutral-100'
            : 'font-normal text-neutral-70'
        }`}
      >
        {label}
      </p>
      <div
        className={`min-h-[4px] ${current ? 'bg-primary' : 'bg-neutral-40'}`}
      />
    </div>
  );
};

const steps = 3;

export function OrgEventNewLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const {onSuccessSubmit} = useSelector((state: any) => state.event);

  const stepRoute = location.pathname.split('/').at(-1);
  const stepIdx = parseInt(stepRoute?.at(-1) || '') - 1;

  const submit = () => {
    dispatch(setOnPublishClicked({
      step: stepRoute,
      timestamp: moment().milliseconds()
    }));
  }

  useEffect(() => {
    if (onSuccessSubmit) {
      if(onSuccessSubmit === 'event-published') {
        window.location.href = '/event';
      }

      navigate(`step-${stepIdx + 2}`);
    }
  }, [onSuccessSubmit])

  return (
    <>
      <div>
        <div className='flex w-full flex-col gap-4 self-center lg:gap-6'>
          <h2 className='text-center text-lg font-medium'>Buat Event</h2>
          <div className='flex justify-stretch gap-2'>
            <StepIndicator label='1. Informasi acara' current={true} />
            <StepIndicator label='2. Tiket' current={stepIdx >= 1} />
            <StepIndicator label='3. Penanggung jawab' current={stepIdx >= 2} />
            <StepIndicator label='4. Publikasi' current={stepIdx >= 3} />
          </div>

          <Outlet />

          <HSeparator />
          <div
            className={cn(
              stepIdx < steps ? 'flex-row' : 'flex-col-reverse md:flex-row',
              'flex gap-4'
            )}
          >
            {stepIdx !== 0 ? (
              <Button
                color='neutral'
                onClick={() => {
                  window.location.href = `step-${stepIdx}`;
                }}
                leftIcon={<ChevronLeftIcon />}
                className='md:mr-auto'
              >
                Sebelumnya
              </Button>
            ) : undefined}
            {stepIdx < steps ? (
              <Button
                onClick={submit}
                rightIcon={<ChevronRightIcon />}
                className='ml-auto'
              >
                Selanjutnya
              </Button>
            ) : (
              <>
                <Button look='outline' onClick={() => {
                  toast.success("Event berhasil disimpan, kamu bisa mengedit kembali lagi dalam waktu 7x24 jam", {
                    duration: 4000
                  });

                  setTimeout(() => {
                    window.location.href = "/event";
                  }, 3000)

                }} leftIcon={<DocumentTextIcon />}>
                  Simpan sebagai draf
                </Button>
                <Button
                  onClick={submit}
                  leftIcon={<DocumentCheckIcon />}
                >
                  Simpan dan publikasi
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
