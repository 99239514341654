import { Button } from '@/components/button';
import { Checkbox } from '@/components/form/checkbox';
import { FormLabel } from '@/components/form/formlabel';
import { Modal } from '@/components/modal';
import { HSeparator } from '@/components/separator';
import {
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React, {useEffect, useState} from 'react';
import {DocumentCheckIcon} from "@heroicons/react/24/solid";
import {Input} from "@/components/form/input";
import {TicketList} from "@/routes/org/event/_ticket/tickets";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "@/root/store";
import {useParams} from "react-router-dom";
import {
  deleteTicket,
  updateChannelChecked,
  updateCustomerDataChecked,
  updateOrAddTicket,
  clearErrors
} from "@/reducers/events";
import {ShowEvent} from "@/services/events";
import {Textarea} from "@/components/form/textarea";
import {UpdateTicketInformation} from "@/services/tickets";
import {getErrors} from "@/reducers/tickets";

export function OrgEventNewStep2() {
  const defaultTicketData = {
    id: null,
    name: '',
    description: '',
    quantity: '0',
    price: '',
    max_buy: 1,
    start_sell_at: '',
    end_sell_at: ''
  };

  const {slug} = useParams();
  const [ticketData, setTicketData] = useState(defaultTicketData);
  const {event, tickets, channels, customer_data, onPublishClicked} = useSelector((state: any) => state.event);
  const {errors} = useSelector((state: any) => state.tickets);
  const dispatch: AppDispatch = useDispatch();

  const [ticketModal, setTicketModal] = useState({
    state: false,
    label: '',
  });

  function onAddTicketClicked() {
    setTicketData(defaultTicketData);
    setTicketModal({
      state: true,
      label: 'create'
    });
  }
console.log(tickets)
  function onEditTicketClicked(ticketId: number) {
    const ticket = tickets.filter((ticket: any) => ticket.id === ticketId)[0];

    setTicketData(ticket);
    setTicketModal({
      state: true,
      label: 'edit'
    });
  }

  function onDeleteTicketClicked(ticketId: number) {
    dispatch(deleteTicket(ticketId));
  }

  const setData = (e: any, element: string) => {
    setTicketData({...ticketData, [element]: e.target.value})

    dispatch(clearErrors());
  }

  useEffect(() => {
    dispatch(ShowEvent(slug))
  }, [])

  const updateChannel = (e: any) => {
    const { value, checked } = e.target;
    dispatch(updateChannelChecked({ id: Number(value), checked }));
  }

  const updateCustomerData = (e: any) => {
    const { value, checked } = e.target;
    dispatch(updateCustomerDataChecked({ id: Number(value), checked }));
  }

  const closeModalTicket = () => {
    dispatch(clearErrors());
    setTicketData(defaultTicketData);

    setTicketModal({
      state: false,
      label: ''
    });
  }

  const updateTicket = () => {
    const validationErrors = validateTicketData(ticketData);
    if (validationErrors) {
      dispatch(getErrors({
        errorMsg: '',
        errors: validationErrors,
      }));

      return;
    }

    dispatch(updateOrAddTicket(ticketData));

    setTicketModal({
      state: false,
      label: ''
    });
  }

  useEffect(() => {
    const channelIds = channels
        .filter((channel: any) => channel.checked)
        .map((channel: any) => channel.id);

    const customerIds = customer_data
        .filter((customer: any) => customer.checked)
        .map((customer: any) => customer.id);

    if (onPublishClicked.step === "step-2") {
        dispatch(UpdateTicketInformation(event.id, {
          tickets: tickets,
          channels: channelIds,
          customer_data: customerIds,
        }));
    }
  }, [onPublishClicked])

  return (
    <>
      <Modal
        show={ticketModal.state}
        onRequestClose={closeModalTicket}
        title={ticketModal.label === 'edit' ? 'Edit tiket' : 'Tambah tiket'}
        body={
          <>
            <div className='mb-3'>
              <FormLabel label='Nama tiket' errorText={errors.name} required>
                <Input type='text' value={ticketData.name} onChange={(e) => setData(e, 'name')} required />
              </FormLabel>
            </div>
            <div className='mb-3 flex justify-stretch gap-2'>
              <FormLabel
                  label='Harga tiket'
                  errorText={errors.price}
                  required
              >
                <Input leftDeco='Rp' type='price' value={ticketData.price} onChange={(e) => setData(e, 'price')} required />
              </FormLabel>
              <FormLabel
                  label='Jumlah tiket'
                  errorText={errors.quantity}
                  required
              >
                <Input type='number' min={0} value={ticketData.quantity} onChange={(e) => setData(e, 'quantity')} required />
              </FormLabel>
              <FormLabel
                  label='Maks. pembelian'
                  errorText={errors.max_buy}
                  required
              >
                <Input type='number' min={0} value={ticketData.max_buy} onChange={(e) => setData(e, 'max_buy')} required />
              </FormLabel>
            </div>
            <div className='mb-3'>
              <FormLabel label='Waktu mulai dijual' errorText={errors.start_sell_at} required>
                <Input
                    type='datetime-local'
                    value={ticketData.start_sell_at}
                    onChange={(e) => setData(e, 'start_sell_at')}
                    required
                />
              </FormLabel>
            </div>
            <div className='mb-3'>
              <FormLabel label='Waktu berakhir dijual' errorText={errors.end_sell_at} required>
                <Input
                    type='datetime-local'
                    value={ticketData.end_sell_at}
                    onChange={(e) => setData(e, 'end_sell_at')}
                    required
                />
              </FormLabel>
            </div>
            <div className={'mb-3'}>
              <FormLabel label='Deskripsi Tiket' errorText={errors.description}>
                <Textarea value={ticketData.description} onChange={(e) => setData(e, 'description')} required />
              </FormLabel>
            </div>
          </>
        }
        footer={
          <div className='flex justify-end gap-3'>
            <Button
              variant='neutral'
              onClick={closeModalTicket}
              leftIcon={<XMarkIcon />}
            >
              Tutup
            </Button>
            <Button
              onClick={() => {
                updateTicket();
              }}
              leftIcon={<DocumentCheckIcon />}
            >
              Simpan
            </Button>
          </div>
        }
      />

      <TicketList
      data={tickets}
      title={
        <p className='flex-grow text-base font-medium text-danger'>
        Daftar tiket
      </p>
      }
      onEditTicketClicked={onEditTicketClicked}
      onDeleteTicketClicked={onDeleteTicketClicked}
      button={
        <Button
            size='sm'
            look='outline'
            onClick={onAddTicketClicked}
            leftIcon={<PlusIcon />}
        >
          Tambah tiket baru
        </Button>
      } />
      {errors.tickets &&
      <p className='block text-xs text-danger'>{errors.tickets}</p>
      }

      <HSeparator />

      <div className={'grid grid-cols-2 gap-2'}>
        <div className='flex flex-col gap-6'>
          <p className='text-base font-medium text-danger'>Metode pembayaran</p>
          <div className='flex flex-col gap-3'>
            {/*<p className='text-sm font-medium text-neutral-70'>E-money</p>*/}
            <div className='flex flex-wrap gap-6'>
              {
                channels.map((channel: any, index: string) => (
                <FormLabel label={channel.name} inline key={index}>
                  <Checkbox checked={channel.checked} value={channel.id} onChange={(e: any) => {
                    updateChannel(e)
                  }} />
                </FormLabel>
                ))
              }
            </div>
            <p className='block text-xs text-danger'>{errors.channels}</p>
          </div>
        </div>
      </div>

      <HSeparator />

      <div className={'grid grid-cols-2 gap-2'}>
        <div className='flex flex-col gap-6'>
          <p className='text-base font-medium text-danger'>Data pembeli</p>
          <div className='flex flex-col gap-3'>
            {/*<p className='text-sm font-medium text-neutral-70'>E-money</p>*/}
            <div className='flex flex-wrap gap-6'>
              {
                customer_data.map((customerData: any, index: string) => (
                    <FormLabel label={customerData.label} inline key={index}>
                      <Checkbox disabled={customerData.mandatory} checked={customerData.checked}
                                onChange={(e: any) => {
                                  updateCustomerData(e)
                                }}
                                value={customerData.id} />
                    </FormLabel>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


const validateTicketData = (ticketData: any) => {
  const errors: any = {};

  // Validate name
  if (!ticketData.name.trim()) {
    errors.name = 'Nama tiket harus diisi.';
  }

  // Validate description
  if (ticketData.description && (ticketData.description.length < 5 || ticketData.description.length > 100)) {
    errors.description = 'Deskripsi harus antara 5 hingga 100 karakter.';
  }

  // Validate quantity
  if (!ticketData.quantity || isNaN(ticketData.quantity) || Number(ticketData.quantity) <= 0) {
    errors.quantity = 'Kuantitas harus angka positif.';
  }

  // Validate quantity
  if (!ticketData.max_buy || isNaN(ticketData.max_buy) || Number(ticketData.max_buy) <= 0) {
    errors.max_buy = 'Maksimal pembelian minimal 1 dan harus angka positif.';
  }

  // Validate price
  if (!ticketData.price || isNaN(ticketData.price) || Number(ticketData.price) < 0) {
    errors.price = 'Harga harus diisi dengan angka valid.';
  }

  // Validate date fields
  const startDate = new Date(ticketData.start_sell_at);
  const endDate = new Date(ticketData.end_sell_at);

  if (!ticketData.start_sell_at) {
    errors.start_sell_at = 'Tanggal mulai dijual wajib diisi.';
  } else if (isNaN(startDate.getTime())) {
    errors.start_sell_at = 'Tanggal mulai tidak valid.';
  }

  if (!ticketData.end_sell_at) {
    errors.end_sell_at = 'Tanggal berakhir wajib diisi.';
  } else if (isNaN(endDate.getTime())) {
    errors.end_sell_at = 'Tanggal berakhir tidak valid.';
  } else if (startDate >= endDate) {
    errors.end_sell_at = 'Tanggal berakhir harus setelah tanggal mulai.';
  }

  return Object.keys(errors).length ? errors : null;
};
