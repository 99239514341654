import axios from "axios";
import {headers} from "@/services/headers"
import {ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {events, loading, getErrors, visitors, transactions, stats} from "@/reducers/dashboard";

export const GetRunningEvents = ():  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .get(process.env.REACT_APP_BASE_URL + '/org/dashboard/events', {
            headers: headers()
        }).then((response) => {
            dispatch(events(response.data.data));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const GetVisitors = ():  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = await axios
        .get(process.env.REACT_APP_BASE_URL + '/org/dashboard/visitors', {
            headers: headers()
        }).then((response) => {
            dispatch(visitors(response.data.data));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const GetTransactions = ():  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = await axios
        .get(process.env.REACT_APP_BASE_URL + '/org/dashboard/transactions?perPage=7', {
            headers: headers()
        }).then((response) => {
            dispatch(transactions(response.data.data));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const GetStats = ():  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = await axios
        .get(process.env.REACT_APP_BASE_URL + '/org/dashboard/stats', {
            headers: headers()
        }).then((response) => {
            dispatch(stats(response.data.data));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};
