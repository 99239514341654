import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    profile: {},
    loading: false,
    onSuccessUploadLogo: false,
    onSuccessUploadBanner: false,
    onSuccessUpdateProfile: false,
    onSuccessUpdatePassword: false,
    errorMsg: '',
    errors: {}
};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfile: (state, action) => {
            state.profile = action.payload;
        },
        setOnSuccessUploadLogo: (state, action) => {
            state.onSuccessUploadLogo = action.payload;
        },
        setOnSuccessUploadBanner: (state, action) => {
            state.onSuccessUploadBanner = action.payload;
        },
        setOnSuccessUpdateProfile: (state, action) => {
            state.onSuccessUpdateProfile = action.payload;
        },
        setOnSuccessUpdatePassword: (state, action) => {
            state.onSuccessUpdatePassword = action.payload;
        },
        getErrors: (state, action) => {
            state.errorMsg = action.payload.errorMsg;
            state.errors = action.payload.errors;
        },
        clearErrors: (state) => {
            state.errorMsg = '';
            state.errors = '';
        },
        loading: (state, action) => {
            state.loading = action.payload;
        }
    },
});

export const { loading, setProfile, setOnSuccessUpdateProfile, setOnSuccessUploadLogo, setOnSuccessUploadBanner, setOnSuccessUpdatePassword, clearErrors, getErrors } = profileSlice.actions;
export default profileSlice.reducer;
