import {FormLabel} from "@/components/form/formlabel";
import {Input} from "@/components/form/input";
import React, {useEffect, useState} from "react";
import {Button} from "@/components/button";
import {DocumentCheckIcon, PencilSquareIcon} from "@heroicons/react/24/solid";
import {Modal} from "@/components/modal";
import {Dropzone} from "@/components/form/dropzone";
import {useDispatch, useSelector} from "react-redux";
import {ShowProfile, UpdateProfile, UploadProfileBanner, UploadProfileLogo} from "@/services/profile";
import {AppDispatch} from "@/root/store";
import {
    clearErrors, getErrors,
    setOnSuccessUploadBanner,
    setOnSuccessUploadLogo,
    setProfile
} from "@/reducers/profile";

interface EditImageState {
    banner: File | undefined;
    logo: File | undefined;
}

export function OrgProfile() {
    const dispatch: AppDispatch = useDispatch();
    const {
        profile,
        onSuccessUpdateProfile,
        onSuccessUploadLogo,
        onSuccessUploadBanner,
        loading,
        errors
    } = useSelector((state: any) => state.profile);
    const [editMode, setEditMode] = useState(false);
    const [editImage, setEditImage] = useState<EditImageState>({
        banner: undefined,
        logo: undefined
    });
    const [changePictureModal, setChangePictureModal] = useState(false);
    const [changeBannerModal, setChangeBannerModal] = useState(false);

    const uploadProfileBanner = (e: any) => {
        e.forEach((image: File) => {
            setEditImage({
                ...editImage,
                banner: image
            });
        })

        getErrors({});
    }

    const uploadProfileLogo = (e: any) => {
        e.forEach((image: File) => {
            setEditImage({
                ...editImage,
                logo: image
            });
        })

        getErrors({});
    }

    const saveImage = () => {
        if (editImage.banner) {
            dispatch(UploadProfileBanner(editImage.banner))
        }

        if (editImage.logo) {
            dispatch(UploadProfileLogo(editImage.logo))
        }
    }

    const updateProfile = () => {
        dispatch(UpdateProfile(profile))
    }

    const setData = (e: any, element: string) => {
        dispatch(setProfile({...profile, [element]: e.target.value}))

        dispatch(clearErrors());
    }

    useEffect(() => {
        if (onSuccessUploadLogo) {
            setChangePictureModal(false)
        }

        if(onSuccessUploadBanner) {
            setChangeBannerModal(false)
        }
    }, [onSuccessUploadBanner, onSuccessUploadLogo])

    useEffect(() => {
        if (onSuccessUpdateProfile) {
            setEditMode(false)
            window.location.reload()
        }
    }, [onSuccessUpdateProfile])

    useEffect(() => {
        dispatch(ShowProfile())
        dispatch(setOnSuccessUploadBanner(false))
        dispatch(setOnSuccessUploadLogo(false))
    }, [onSuccessUploadBanner, onSuccessUploadLogo, onSuccessUpdateProfile])

    return (
        <>
            <Modal
                show={changePictureModal}
                onRequestClose={() => setChangePictureModal(false)}
                title='Edit foto profil'
                body={
                    <>
                        <div className={'w-full'}>
                            <FormLabel label='Foto profil' errorText={errors.image}>
                                <Dropzone onChange={uploadProfileLogo} maxFiles={1}/>
                            </FormLabel>
                        </div>
                    </>
                }
                footer={
                    <div className='flex justify-end gap-3'>
                        <Button
                            loading={loading}
                            onClick={saveImage}
                            disabled={!editImage.logo}
                            leftIcon={<DocumentCheckIcon/>}
                        >
                            Simpan
                        </Button>
                    </div>
                }
            />

            <Modal
                show={changeBannerModal}
                onRequestClose={() => setChangeBannerModal(false)}
                title='Edit banner'
                body={
                    <>
                        <div className={'w-full'}>
                            <FormLabel label='Foto banner' errorText={errors.image}>
                                <Dropzone onChange={uploadProfileBanner} maxFiles={1}/>
                            </FormLabel>
                        </div>
                    </>
                }
                footer={
                    <div className='flex justify-end gap-3'>
                        <Button
                            loading={loading}
                            onClick={saveImage}
                            disabled={!editImage.banner}
                            leftIcon={<DocumentCheckIcon/>}
                        >
                            Simpan
                        </Button>
                    </div>
                }
            />

            <div className={'text-center mx-4 mt-4 md:mt-0 md:mx-0'}>
                <div className={'relative'}>
                    <div className={'absolute p-2'}>
                        <Button onClick={() => {
                            setChangeBannerModal(true)
                        }} className={'text-sm font-semibold cursor-pointer'}>Edit Banner</Button>
                    </div>
                    <img
                        src={profile.banner?.fallback_url ?? 'https://assets-gerra.s3.ap-southeast-1.amazonaws.com/%23DariMahasiswaUntukBangsa.png'}
                        srcSet={profile.banner?.srcset}
                        alt="Banner"
                        className="h-48 w-full object-cover bg-gradient-to-r from-purple-500 to-pink-500"
                    />
                </div>
                <div className={'relative'}>
                    <div className={'flex justify-center -mt-12'}>
                        <img
                            src={profile.logo?.fallback_url ?? 'https://ui-avatars.com/api/?name=Tokoevent'}
                            srcSet={profile.logo?.srcset}
                            alt="Logo"
                            className="bg-purple-500 w-32 h-32 text-4xl font-bold object-cover rounded-full"
                        />
                    </div>
                    <div className={'mt-2'}>
                        <span onClick={() => {
                            setChangePictureModal(true)
                        }} className={'text-sm font-semibold underline cursor-pointer'}>Edit Foto Profil</span>
                    </div>
                </div>
            </div>

            <div className={'md:flex justify-center mt-8 mx-4 md:mx-0'}>
                <div className={'md:w-2/3'}>
                    <div className={'grid md:grid-cols-2 gap-2'}>
                        <div className="mb-3">
                            <FormLabel label='Nama' errorText={errors.name} required>
                                <Input type='text' disabled={!editMode} value={profile.name}
                                       onChange={(e) => setData(e, 'name')} required/>
                            </FormLabel>
                        </div>
                        <div className="mb-3">
                            <FormLabel label='Profile Url' errorText={errors.username} required>
                                <Input leftDeco={'https://tokoevent.id/'} disabled={!editMode} value={profile.username}
                                       onChange={(e) => setData(e, 'username')} type='text' required/>
                            </FormLabel>
                        </div>
                    </div>
                    <div className={'grid md:grid-cols-2 gap-2'}>
                        <div className="mb-3">
                            <FormLabel label='Email' errorText={errors.email} required>
                                <Input type='email' disabled={true} value={profile.email}
                                       onChange={(e) => setData(e, 'email')} required/>
                            </FormLabel>
                        </div>
                        <div className="mb-3">
                            <FormLabel label='Whatsapp' errorText={errors.phone} required>
                                <Input leftDeco={profile.country?.phone_code} disabled={true} value={profile.phone}
                                       onChange={(e) => setData(e, 'phone')} type='text' required/>
                            </FormLabel>
                        </div>
                    </div>
                    <div className='flex justify-end gap-3'>
                        {editMode ?
                            <Button
                                loading={loading}
                                onClick={updateProfile}
                                className={'w-full md:w-max mt-4'}
                                leftIcon={<DocumentCheckIcon/>}
                            >
                                Simpan
                            </Button>
                            : <Button
                                variant={'neutral'}
                                look={'outline'}
                                onClick={() => {
                                    setEditMode(true)
                                }}
                                className={'w-full md:w-max mt-4'}
                                leftIcon={<PencilSquareIcon/>}
                            >
                                Edit
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
