import { Modal } from '@/components/modal';
import {TicketCard} from "@/routes/org/event/_ticket/ticketcard";
import React from "react";

export interface TicketListModalProps {
    tickets: any,
    currentTicket?: string;
  show: boolean;
  flowStep?: number;
  onRequestClose?: () => void;
    onTicketClicked?: (id: string) => void;
}

export const TicketListModal: React.FC<TicketListModalProps> = ({
  tickets = [],
  currentTicket,
  show,
  onRequestClose,
  onTicketClicked,
}) => {
  return (
    <Modal
      show={show}
      onRequestClose={onRequestClose}
      title='Pilih tiket'
      width='large'
      body={
        <div>
            {tickets.length === 0 && <div className={'text-center'}>
                <p>Belum ada tiket</p>
            </div>}
            <div className='grid md:grid-cols-2 gap-4'>
                {
                    tickets.map((ticket: any, key: string) => (
                        <TicketCard
                            key={key}
                            ticket={ticket}
                          isCurrentTicket={currentTicket === ticket.id}
                          onTicketClicked={onTicketClicked}
                        />
                    ))
                }
            </div>
        </div>
      }
    />
  );
};
