import React, {useEffect, useState} from "react";
import {Input} from "@/components/form/input";
import {FormLabel} from "@/components/form/formlabel";
import {Button} from '@/components/button';
import {CheckIcon} from "@heroicons/react/24/solid";
import 'primeicons/primeicons.css';
import {clearErrors, getLoginGoogle, loginGoogleCallback, register} from "@/reducers/auth";
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {AppDispatch} from "@/root/store";

export function Register() {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const scope = searchParams.get('scope');
    const authUser = searchParams.get('authuser');
    const prompt = searchParams.get('prompt');

    const dispatch: AppDispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        username: '',
        password: ''
    });

    const { loading, errors } = useSelector((state: any) => state.auth);

    useEffect(() => {
        if(code) {
            dispatch(loginGoogleCallback(code, scope, authUser, prompt))
        }
    }, [code, scope, authUser, prompt, dispatch])

    const loginGoogleSubmit = () => {
        dispatch(getLoginGoogle())
    }

    const submit = (e: any) => {
        e.preventDefault();

        dispatch(register(formData));
    }

    const setData = (e: any, element: string) => {
        setFormData({...formData, [element]: e.target.value})

        dispatch(clearErrors());
    }

    return (
        <>
            <div className="text-900 text-3xl font-medium mb-1">Selamat datang! 👋🏻</div>
            <p className={"text-400 mb-4"}>Buat akun biar bisa kamu segera jual tiket</p>
            <form onSubmit={submit}>
                <div>
                    <div className="mb-3">
                        <FormLabel label='Nama Penyelenggara' errorText={errors.name} required>
                            <Input type='text' onChange={(e) => setData(e, 'name')} required/>
                        </FormLabel>
                    </div>
                        <div className="mb-3 lg:col-span-2">
                            <FormLabel label='Username' errorText={errors.username} required>
                                <Input type='text' onChange={(e) => setData(e, 'username')} required/>
                            </FormLabel>
                        </div>
                    <div className="mb-3">
                        <FormLabel label='Email' errorText={errors.email} required>
                            <Input type='email' onChange={(e) => setData(e, 'email')} required/>
                        </FormLabel>
                    </div>
                    <div className="mb-3">
                        <FormLabel label='Password' errorText={errors.password} required>
                            <Input type='password' onChange={(e) => setData(e, 'password')} required/>
                        </FormLabel>
                    </div>
                    <div className="flex align-items-center justify-content-between mb-6">
                        <div className="flex align-items-center">
                            <CheckIcon className={'w-8 mr-2'}/><small>Dengan mendaftar, saya berarti telah menyetujui
                            seluruh <a target={'_blank'} rel="noreferrer" href={'https://tokoevent.id/syarat-ketentuan'} className={"underline font-semibold"}>syarat dan ketentuan</a> yang berlaku</small>
                        </div>
                    </div>
                    <div>
                        <Button
                            loading={loading === 'manual'}
                            className={'w-full'}
                            color='neutral'
                            leftIcon={<CheckIcon/>}
                        >Daftar</Button>
                        <Button
                            loading={loading === 'google'}
                            onClick={loginGoogleSubmit}
                            variant={'neutral'}
                            look={'outline'}
                            className={'w-full mt-2'}
                            color='neutral'
                            leftIcon={<i className="pi pi-google"></i>}
                        >Daftar dengan Google</Button>
                    </div>
                </div>
            </form>
            <div className={"mt-4 text-center"}>
                <span className="text-600 font-medium line-height-3">Sudah punya akun?</span>
                {' '}<a href={'login'} className={'underline font-semibold'}>Login yuk!</a>
            </div>
        </>
    )
}
