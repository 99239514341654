import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    tickets: [],
    ticket: [],
    loading: false,
    onPublishClicked: '',
    onSuccessSubmit: false,
    errorMsg: '',
    errors: {}
};

const ticketSlice = createSlice({
    name: 'tickets',
    initialState,
    reducers: {
        tickets: (state, action) => {
            state.tickets = action.payload;
        },
        setTicket: (state, action) => {
            state.ticket = action.payload;
        },
        getErrors: (state, action) => {
            state.errorMsg = action.payload.errorMsg;
            state.errors = action.payload.errors;
        },
        clearErrors: (state) => {
            state.errorMsg = '';
            state.errors = '';
        },
        loading: (state, action) => {
            state.loading = action.payload;
        },
        setOnPublishClicked: (state, action) => {
            state.onPublishClicked = action.payload;
        },
        setOnSuccessSubmit: (state) => {
            state.onSuccessSubmit = true;
        },
    },
});

export const { loading, tickets, setTicket, clearErrors, setOnPublishClicked, setOnSuccessSubmit, getErrors } = ticketSlice.actions;
export default ticketSlice.reducer;
