import axios from "axios";
import {headers} from "@/services/headers"
import {ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {
    setSuccessMsg,
    setVerified,
    setVerifiedError,
    loading,
    getErrors,
    setSuccessMsgEmail,
    authSuccess, updateUser
} from "@/reducers/auth";

export const login = (username: string, password: string) => {
    return axios
        .post(process.env.REACT_APP_BASE_URL + '/org/auth/login', {
            username: username,
            password: password,
        }, {
            headers: headers()
        });
};

export const RegisterService = (formData: {
    name: string,
    email: string,
    username: string,
    password: string
}) => {
    return axios
        .post(process.env.REACT_APP_BASE_URL + '/org/auth/register', {
            name: formData.name,
            email: formData.email,
            username: formData.username,
            password: formData.password,
            password_confirmation: formData.password
        }, {
            headers: headers()
        });
};

export const getLoginGoogle = () => {
    return axios
        .post(process.env.REACT_APP_BASE_URL + '/org/auth/google/login', {}, {
            headers: headers()
        });
};

export const loginGoogleCallback = (code: string, scope?: string|null, authUser?: string|null, prompt?: string|null) => {
    return axios
        .get(process.env.REACT_APP_BASE_URL + `/org/auth/google/callback?code=${code}&scope=${scope}&authuser=${authUser}&prompt=${prompt}`, {
            headers: headers()
        });
};

export const SendLinkResetPasswordService = (email: string) => {
    return axios
        .post(process.env.REACT_APP_BASE_URL + '/org/auth/email-reset-link', {
            email: email
        }, {
            headers: headers()
        });
};

export const LogoutService = () => {
    return axios
        .delete(process.env.REACT_APP_BASE_URL + '/org/auth/logout', {
            headers: headers()
        });
};

export const CompleteRegister = (formData: {
    phone: string,
    country_id: string,
}):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    const response = axios
        .post(process.env.REACT_APP_BASE_URL + '/org/auth/complete', {
            phone: formData.phone,
            country_id: formData.country_id,
        }, {
            headers: headers()
        }).then((response) => {
            dispatch(setSuccessMsg(response.data.message));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const SendEmailVerification = ():  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    const response = axios
        .get(process.env.REACT_APP_BASE_URL + '/org/auth/verification/email/send', {
            headers: headers()
        }).then((response) => {
            dispatch(setSuccessMsgEmail(response.data.message));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const VerifyCode = (whatsappCode: string, emailCode: string):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    const response = axios
        .post(process.env.REACT_APP_BASE_URL + '/org/auth/verification/verify', {
            type: {
                whatsapp: {
                    type: "whatsapp",
                    code: whatsappCode
                },
                email: {
                    type: "email",
                    code: emailCode
                },
            }
        }, {
            headers: headers()
        }).then((response) => {
            dispatch(updateUser(response.data.data));
            dispatch(setVerified());
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(setVerifiedError({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const UpdatePassword = (formData: any):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    const response = axios
        .patch(process.env.REACT_APP_BASE_URL + '/org/auth/password', {
            email: formData.email,
            token: formData.token,
            password: formData.password,
            password_confirmation: formData.password
        }, {
            headers: headers()
        }).then((response) => {
            dispatch(setSuccessMsg("Berhasil mengubah password"));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(setVerifiedError({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};
