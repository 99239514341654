import {FormLabel} from "@/components/form/formlabel";
import {Input} from "@/components/form/input";
import React, {useEffect, useState} from "react";
import {Button} from "@/components/button";
import {DocumentCheckIcon} from "@heroicons/react/24/solid";
import {UpdatePassword} from "@/services/profile";
import {clearErrors} from "@/reducers/profile";
import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";

export function OrgProfileSecurity() {
    const dispatch: AppDispatch = useDispatch();
    const {errors, onSuccessUpdatePassword} = useSelector((state: any) => state.profile);
    const [formData, setFormData] = useState({
        current_password: '',
        password: '',
        password_confirmation: '',
    })

    const updatePassword = () => {
        dispatch(UpdatePassword(formData))
    }

    const setData = (e: any, element: string) => {
        setFormData({...formData, [element]: e.target.value});

        dispatch(clearErrors());
    }

    useEffect(() => {
        if(onSuccessUpdatePassword) {
            window.location.href = "info";
        }
    }, [onSuccessUpdatePassword])

    return (
        <>
            <div className={'md:flex justify-center mx-4 md:mx-0 mt-8'}>
                <div className={'md:w-2/3'}>
                    <div className="mb-3">
                        <FormLabel label='Password Lama' errorText={errors.current_password} required>
                            <Input type='password' value={formData.current_password} onChange={(e) => setData(e, 'current_password')} required/>
                        </FormLabel>
                    </div>
                    <div className="mb-3">
                        <FormLabel label='Password Baru' successText={onSuccessUpdatePassword} errorText={errors.password} required>
                            <Input type='password' value={formData.password} onChange={(e) => setData(e, 'password')} required/>
                        </FormLabel>
                    </div>
                    <div className="mb-3">
                        <FormLabel label='Konfirmasi Password Baru' required>
                            <Input type='password' value={formData.password_confirmation} onChange={(e) => setData(e, 'password_confirmation')} required/>
                        </FormLabel>
                    </div>
                    <div className='flex justify-end gap-3'>
                        <Button
                            onClick={updatePassword}
                            className={'w-full md:w-max mt-4'}
                            leftIcon={<DocumentCheckIcon/>}
                        >
                            Simpan
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}
