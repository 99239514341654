import React, {useState} from "react";
import {Input} from "@/components/form/input";
import {FormLabel} from "@/components/form/formlabel";
import {Button} from '@/components/button';
import {CheckIcon} from "@heroicons/react/24/solid";
import {AppDispatch} from "../../../../store";
import {useDispatch, useSelector} from "react-redux";
import {clearText} from "@/reducers/passwords";
import {sendLinkResetPassword} from "@/reducers/passwords";

export function SendLinkResetPassword() {
    const dispatch: AppDispatch = useDispatch();
    const [formData, setFormData] = useState({
        email: ''
    });

    const { loading, successMsg, errorMsg } = useSelector((state: any) => state.passwords);

    const submit = (e: any) => {
        e.preventDefault();

        dispatch(sendLinkResetPassword(formData.email))
    }

    const setData = (e: any, element: string) => {
        setFormData({...formData, [element]: e.target.value})

        dispatch(clearText());
    }

    return (
        <>
            <div className="text-900 text-3xl font-medium mb-1">Kirim link ke email ✈️</div>
            <p className={"text-400 mb-4"}>Kami akan mengirimkan link untuk mengubah password</p>
            <form onSubmit={submit}>
                <div>
                    <div className="mb-3">
                        <FormLabel label='Email' successText={successMsg} errorText={errorMsg} required>
                            <Input type='email' onChange={(e) => setData(e, 'email')} required/>
                        </FormLabel>
                    </div>

                    <div>
                        <Button
                            loading={loading}
                            type="submit"
                            className={'w-full'}
                            color='neutral'
                            leftIcon={<CheckIcon/>}
                        >Kirim Link</Button>
                    </div>
                </div>
            </form>
        </>
    )
}
