import axios from "axios";
import {headers} from "@/services/headers"
import {countries, getErrors, loading} from "@/reducers/helpers";
import {ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../store";

export const GetCountries = ():  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .get(process.env.REACT_APP_BASE_URL + '/helpers/countries', {
            headers: headers()
        }).then((response) => {
            dispatch(countries(response.data.data));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};
