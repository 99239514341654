import axios from "axios";
import {headers} from "@/services/headers"
import {ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {
    loading,
    getErrors,
    setProfile,
    setOnSuccessUploadBanner,
    setOnSuccessUploadLogo,
    setOnSuccessUpdateProfile, setOnSuccessUpdatePassword
} from "@/reducers/profile";

export const ShowProfile = ():  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .get(process.env.REACT_APP_BASE_URL + '/org/profile', {
            headers: headers()
        }).then((response) => {
            dispatch(setProfile(response.data.data));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const UploadProfileBanner = (image?: File):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .post(process.env.REACT_APP_BASE_URL + '/org/profile/banner', {
            image: image
        },{
            headers: {...headers(), "Content-Type": "multipart/form-data"}
        }).then((response) => {
            dispatch(setOnSuccessUploadBanner(true))

            dispatch(loading(false))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));

            dispatch(loading(false))
        });

    return response;
};

export const UploadProfileLogo = (image?: File):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .post(process.env.REACT_APP_BASE_URL + '/org/profile/logo', {
            image: image
        },{
            headers: {...headers(), "Content-Type": "multipart/form-data"}
        }).then((response) => {
            dispatch(setOnSuccessUploadLogo(true))

            dispatch(loading(false))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));

            dispatch(loading(false))
        });

    return response;
};

export const UpdateProfile = (profile: any):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .patch(process.env.REACT_APP_BASE_URL + '/org/profile', profile,{
            headers: headers()
        }).then((response) => {
            dispatch(setOnSuccessUpdateProfile(true))

            dispatch(loading(false))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));

            dispatch(loading(false))
        });

    return response;
};

export const UpdatePassword = (formData: any):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .patch(process.env.REACT_APP_BASE_URL + '/org/profile/password', formData,{
            headers: headers()
        }).then((response) => {
            dispatch(setOnSuccessUpdatePassword(response.data.message))

            dispatch(loading(false))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));

            dispatch(loading(false))
        });

    return response;
};
