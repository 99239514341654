import loginHeroImage from "@/routes/org/auth/_assets/login.png";
import registerHeroImage from "@/routes/org/auth/_assets/register.png";
import React, {useEffect} from "react";
import {Outlet, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

export function AuthLayout() {
  const location = useLocation();
  const pathArray = location.pathname.substring(0).split('/').slice(2)[0];

  const authToken = localStorage.getItem('authToken');

  const { isLoggedIn, user } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if(isLoggedIn && authToken) {
      window.location.href = '/';
    } else if(isLoggedIn && !user.email_verified_at) {
      window.location.href = '/auth/complete';
    }
  }, [isLoggedIn, user.phone, authToken])

  return(
      <>
        <div className="grid md:grid-cols-3 grid-cols-1 surface-0 text-800">
          <div className="md:col-span-2 overflow-hidden hidden md:block h-screen">
            <img src={pathArray === 'login' ? loginHeroImage : registerHeroImage} alt="hero-1" className="h-full w-full object-cover" style={{objectFit: "cover"}} />
          </div>
          <div className="md:col-span-1 p-8 h-screen">
            <section>
              <div className="mb-5">
                <div className="shrink-0 mb-4 text-left">
                  <img
                      src='/logo.png'
                      alt='Logo'
                      width={112}
                      height={50}
                      className='h-[50px] object-contain'
                  />
                </div>
              </div>
              <div className={"mt-16 justify-content-center items-center"}>
                <Outlet />
              </div>
            </section>
            <div className="relative">
              <div className="absolute inset-x-0 -bottom-12 text-center">&copy; tokoevent.com</div>
            </div>
          </div>
        </div>
      </>
  )
}
