import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {PublishEvent, ShowEvent} from "@/services/events";
import {useParams} from "react-router-dom";
import confetti from './_assets/confetti.gif'
import {
    EnvelopeIcon,
} from "@heroicons/react/24/solid";
import {Button} from "@/components/button";

export function OrgEventNewStep4() {
    const {slug} = useParams();
    const dispatch: AppDispatch = useDispatch();
    const {event, onPublishClicked, documents, errors} = useSelector((state: any) => state.event);

    useEffect(() => {
        dispatch(ShowEvent(slug))
    }, [])

    useEffect(() => {
        if (onPublishClicked.step === "step-4") {
            dispatch(PublishEvent(event.id))
        }
    }, [onPublishClicked])

    return (
        <div className={'lg:w-[480px] mx-auto'}>
            <div className='flex justify-center gap-2'>
                <div className={'text-center'}>
                    <img
                        className='w-20 shrink-0 grow-0 object-cover'
                        src={confetti}
                        alt={'success icon'}
                    />
                </div>
            </div>
            <h2 className='text-center text-lg font-medium'>Asikk, event kamu sudah lengkap!🥳</h2>
            <div className={'flex justify-center mt-12'}>
                <img
                    className='h-32 lg:w-48 w-1/2 shrink-0 grow-0 object-cover rounded-xl border-[1px] border-neutral-40'
                    src={event.banner.fallback_url}
                    srcSet={event.banner.srcset}
                    alt={event.title}
                />
            </div>
            <div className={'mt-4'}>
                <h2 className='text-center text-md'>Klik simpan & publikasi maka kami akan segera mengirimkan surat perjanjian kerjasama ke nomor hp penanggung jawab event kamu👋🏻</h2>
                <div className={'text-center mt-4'}>
                    <h2 className='text-center text-sm mb-2'>Jika kamu ada pertanyaan, silakan whatsapp admin kami yaa🙌🏻</h2>
                    <div className={'flex justify-center gap-2'}>
                        <Button
                            element={'a'}
                            target={'_blank'}
                            href={'https://wa.me/6281238169667'}
                            leftIcon={<EnvelopeIcon />}
                        >
                            Hubungi Kami
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
