import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    guests: [],
    paginator: [],
    exportRoute: '',
    loading: false,
    errorMsg: '',
    errors: {}
};

const guestSlice = createSlice({
    name: 'guests',
    initialState,
    reducers: {
        setGuests: (state, action) => {
            state.guests = action.payload;
        },
        setPaginator: (state, action) => {
            state.paginator = action.payload;
        },
        setExportRoute: (state, action) => {
            state.exportRoute = action.payload;
        },
        getErrors: (state, action) => {
            state.errorMsg = action.payload.errorMsg;
            state.errors = action.payload.errors;
        },
        clearErrors: (state) => {
            state.errorMsg = '';
            state.errors = '';
        },
        loading: (state, action) => {
            state.loading = action.payload;
        }
    },
});

export const { loading, setGuests, setPaginator, setExportRoute, clearErrors, getErrors } = guestSlice.actions;
export default guestSlice.reducer;
