import { Button } from '@/components/button';
import { Table } from '@/components/table';
import {
  EllipsisHorizontalIcon, PencilSquareIcon, TrashIcon
} from '@heroicons/react/24/outline';
import {FormatCurrency} from "@/routes/root";
import moment from "moment";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "@/components/dropdown";

export interface TicketProps {
  title: React.ReactNode;
  button?: React.ReactNode;
  mode?: 'edit' | 'info';
  data: any;
  onEditTicketClicked?: any,
  onDeleteTicketClicked?: any,
}

export const TicketList: React.FC<TicketProps> = ({title, button, data, onEditTicketClicked, onDeleteTicketClicked, mode = 'edit'}) => {
  return (
    <>
      <div className='flex flex-col gap-6'>
        <div className='flex items-center'>
          {title}
          {button}
        </div>
        <Table className='overflow-scroll whitespace-nowrap md:whitespace-normal'>
          <thead>
            <tr>
              <th>No.</th>
              <th>Nama tiket</th>
              <th>Harga</th>
              <th>Jumlah tiket {mode === 'info' && 'terjual'}</th>
              {mode === 'info' && <th>Jumlah tiket belum dibayar</th>}
              <th>Tgl jual</th>
              {mode === 'edit' && <th>Aksi</th>}
            </tr>
          </thead>
          <tbody>
          { data.map((ticket: any, index: string) => (
            <tr key={index}>
                <td>{index + 1}</td>
                <td>{ticket.name}</td>
                <td>
                  <span className='text-danger'>
                    <FormatCurrency amount={ticket.price} currency={ticket.currency_code} />
                  </span>
                </td>
                <td>{mode === 'info' && <span className={'text-red-500'}>{ticket.number_tickets_sold}/</span>}{ticket.quantity}</td>
              {mode === 'info' && <td><span className={'text-red-500'}>{ticket.number_tickets_onhold}</span></td>}
                <td>{moment(ticket.start_sell_at).format('lll')} - {moment(ticket.end_sell_at).format('lll')}</td>
              {mode === 'edit' && <td>
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <Button
                          size='sm'
                          variant='neutral'
                          leftIcon={<EllipsisHorizontalIcon />}
                      />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem className={'cursor-pointer'} onClick={() => {
                        onEditTicketClicked(ticket.id)
                      }}>
                        <PencilSquareIcon className='mr-2 h-4 w-4' />
                        <span>Ubah</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem className={'cursor-pointer'} onClick={() => {
                        onDeleteTicketClicked(ticket.id)
                      }}>
                        <TrashIcon className='mr-2 h-4 w-4 text-danger' />
                        <span className='text-danger'>Hapus</span>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </td>}
              </tr>
            ))
          }
          </tbody>
        </Table>
      </div>
    </>
  );
}
