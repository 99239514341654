import {Dropzone} from '@/components/form/dropzone';
import {FormLabel} from '@/components/form/formlabel';

import {Input} from "@/components/form/input";
import {clearErrors} from "@/reducers/events";
import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {
    DeleteDocument,
    GetDocumentInformation,
    UpdateDocumentInformation,
    UploadDocumentIdentity,
    UploadDocumentPermit
} from "@/services/documents";
import {ShowEvent} from "@/services/events";
import {useParams} from "react-router-dom";

const identityDefaultValue = {
    holder_name: '',
    phone: '',
    address: '',
    number: '',
};

export function OrgEventNewStep3() {
    const {slug} = useParams();
    const dispatch: AppDispatch = useDispatch();
    const {event, onPublishClicked, documents, errors} = useSelector((state: any) => state.event);
    const [identityData, setIdentityData] = useState(identityDefaultValue);

    const document = documents.filter((doc: any) => doc.type === 'identity')[0];
    const documentPermit = documents.filter((doc: any) => doc.type === 'permit')[0];

    const setData = (value: string, element: string) => {
        setIdentityData({...identityData, [element]: value})

        dispatch(clearErrors());
    };

    const uploadDocument = (e: any, type: 'identity' | 'permit') => {
        const file = e[0];

        if(type === 'identity') {
            dispatch(UploadDocumentIdentity(event.id, file))
        } else if(type === 'permit') {
            dispatch(UploadDocumentPermit(event.id, file))
        }
    }

    useEffect(() => {
        dispatch(ShowEvent(slug))
        dispatch(GetDocumentInformation(slug))
    }, [])

    useEffect(() => {
        setIdentityData(document?.identity ?? identityDefaultValue)
    }, [document])

    useEffect(() => {
        if (onPublishClicked.step === "step-3") {
            dispatch(UpdateDocumentInformation(event.id, identityData))
        }
    }, [onPublishClicked])

    return (
        <>
            <div className='md:flex justify-stretch gap-2'>
                <div className='flex-grow md:mb-0 mb-3'>
                    <FormLabel label='KTP penanggung jawab' errorText={errors?.identity} required>
                        <Dropzone onChange={(e) => {
                            uploadDocument(e, 'identity')
                        }} onDelete={() => {
                            dispatch(DeleteDocument(event.id, document?.id))
                        }} previewUrl={document?.file_url} maxFiles={1}/>
                    </FormLabel>
                </div>
                <div className='flex-grow'>
                    <div className={'mb-3 flex justify-stretch gap-2'}>
                        <FormLabel label='Nama sesuai KTP' className='flex-grow' errorText={errors.holder_name} required>
                            <Input type='text'
                                   value={identityData.holder_name}
                                   onChange={(e) => setData(e.target.value, 'holder_name')}
                                   required/>
                        </FormLabel>
                        <FormLabel label='No. KTP' className='flex-grow' errorText={errors.number} required>
                            <Input type='identity'
                                   value={identityData.number}
                                   onChange={(e) => setData(e.target.value, 'number')}
                                   required/>
                        </FormLabel>
                    </div>
                    <div className={'mb-3 flex justify-stretch gap-2'}>
                        <FormLabel label='Alamat sesuai KTP' className='flex-grow' errorText={errors.address} required>
                            <Input type='text'
                                   value={identityData.address}
                                   onChange={(e) => setData(e.target.value, 'address')}
                                   required/>
                        </FormLabel>
                        <FormLabel label='No. Hp' className='flex-grow' errorText={errors.phone} required>
                            <Input type='phone'
                                   value={identityData.phone}
                                   onChange={(e) => setData(e.target.value, 'phone')}
                                   required/>
                        </FormLabel>
                    </div>
                </div>
            </div>

            {/* TODO: add PDF support for the last two */}

            <FormLabel label='Izin penyelenggara event' errorText={errors?.permit} required>
                <Dropzone onChange={(e) => {
                    uploadDocument(e, 'permit')
                }} onDelete={() => {
                    dispatch(DeleteDocument(event.id, documentPermit?.id))
                }} previewUrl={documentPermit?.file_url} maxFiles={1}/>
            </FormLabel>
        </>
    );
}
