import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import store from './store'

import { Root } from './routes';
import { OrgCommerceLayout } from './routes/org/commerce/layout';
import { OrgCommercePayment } from './routes/org/commerce/payment/root';
import { OrgDashboard } from './routes/org/dashboard';
import { OrgDashboardAttendeesBySlug } from './routes/org/dashboard/attendees';
import { OrgEvent } from './routes/org/event';
import OrgEventNewCheckout from './routes/org/event/checkout/slug';
import { OrgEventNewCustomPlan } from './routes/org/event/new/custom-plan/root';
import { OrgEventNewLayout } from './routes/org/event/new/layout';
import { OrgEventNewStep1 } from './routes/org/event/new/step-1';
import { OrgEventNewStep2 } from './routes/org/event/new/step-2';
import { OrgEventNewStep3 } from './routes/org/event/new/step-3';
import { OrgEventDetailAttendee } from './routes/org/event/slug/attendee';
import { OrgEventDetailInfo } from './routes/org/event/slug/info';
import { OrgEventDetailLayout } from './routes/org/event/slug/layout';
import { OrgEventDetailPlan } from './routes/org/event/slug/plan';
import { OrgEventDetailTransaction } from './routes/org/event/slug/transaction';
import { OrgEventDetailVouchersDetail } from './routes/org/event/slug/voucher/id';
import { OrgLayout } from './routes/org/layout';
import { OrgTransaction, OrgTransactionDetail } from './routes/org/transaction';

import './index.css';
import { OrgCommerceWithdrawal } from './routes/org/commerce/withdrawal/root';
import {QrCodeDashboard} from "@/routes/org/qrcode";
import {Login} from "@/routes/org/auth/login";
import {Register} from "@/routes/org/auth/register";
import {AuthCreateEvent} from "@/routes/org/auth/event/create";
import {AuthLayout} from "@/routes/org/auth";
import {ResetPassword} from "@/routes/org/auth/password/reset";
import {SendLinkResetPassword} from "@/routes/org/auth/password/email";
import {OrgProfileLayout} from "@/routes/org/profile";
import {OrgProfile} from "@/routes/org/profile/info";
import {OrgProfileSecurity} from "@/routes/org/profile/security";
import {OrgOtsSystemLayout} from "@/routes/org/event/slug/ots/layout";
import {OrgTicketList} from "@/routes/org/event/slug/ticket";
import { Committees } from './routes/org/committees';
import { OrgEventCommittee } from '@/routes/org/event/slug/committee';
import {Provider} from "react-redux";
import {Complete} from "@/routes/org/auth/complete";
import {OrgEventNewStep4} from "@/routes/org/event/new/step-4";
import {Toaster} from "react-hot-toast";
import NotFound from "@/routes/error/404";

const router = createBrowserRouter([
  {
    path: 'auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
      {
        path: 'reset-password/send-email',
        element: <SendLinkResetPassword />,
      },
      {
        path: 'register',
        element: <Register />,
      },
      {
        path: 'complete',
        element: <Complete />,
      },
      {
        path: 'event',
        element: <AuthCreateEvent />,
      },
    ]
  },
  {
    path: '/',
    element: <OrgLayout />,
    children: [
      {
        path: 'qrcode',
        index: true,
        element: <QrCodeDashboard />,
      },
      {
        path: 'dashboard',
        index: true,
        element: <OrgDashboard />,
      },
      {
        path: 'dashboard/attendees/:slug',
        element: <OrgDashboardAttendeesBySlug />,
      },
      {
        path: 'transaction',
        element: <OrgTransaction />,
      },
      {
        path: 'transaction/:id',
        element: <OrgTransactionDetail />,
      },
      {
        path: 'event',
        element: <OrgEvent />,
      },
      {
        path: 'event/:slug/edit',
        element: <OrgEventNewLayout />,
        children: [
          {
            path: 'step-1',
            element: <OrgEventNewStep1 />,
          },
          {
            path: 'step-2',
            element: <OrgEventNewStep2 />,
          },
          {
            path: 'step-3',
            element: <OrgEventNewStep3 />,
          },
          {
            path: 'step-4',
            element: <OrgEventNewStep4 />,
          },
        ],
      },
      {
        path: 'event/new/custom-plan',
        element: <OrgEventNewCustomPlan />,
      },
      {
        path: 'event/new/checkout/:slug',
        element: <OrgEventNewCheckout />,
      },
      {
        path: 'event/:slug',
        element: <OrgEventDetailLayout />,
        children: [
          {
            path: 'info',
            element: <OrgEventDetailInfo />,
          },
          {
            path: 'ots',
            element: <OrgOtsSystemLayout />,
          },
          {
            path: 'ticket',
            element: <OrgTicketList />,
          },
          {
            path: 'committee',
            element: <OrgEventCommittee />,
          },
          /*{
            path: 'voucher',
            element: <OrgEventDetailVouchers />,
          },*/
          {
            path: 'transaction',
            element: <OrgEventDetailTransaction />,
          },
          {
            path: 'attendee',
            element: <OrgEventDetailAttendee />,
          },
        ],
      },
      {
        path: 'event/:slug/voucher/:id',
        element: <OrgEventDetailVouchersDetail />,
      },
      {
        path: 'commerce',
        element: <OrgCommerceLayout />,
        children: [
          {
            path: 'payment',
            element: <OrgCommercePayment />,
          },
          {
            path: 'withdrawal',
            element: <OrgCommerceWithdrawal />,
          },
        ],
      },
      {
        path: 'committee',
        element: <Committees />,
      },
      {
        path: 'setting',
        element: <OrgProfileLayout />,
        children: [
          {
            path: 'info',
            element: <OrgProfile />,
          },
          {
            path: 'security',
            element: <OrgProfileSecurity />,
          }
        ]
      },
      {
        path: '*',
        element: <NotFound />
      }
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
      <RouterProvider router={router} />
      <Toaster />
    </Provider>
);

// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
