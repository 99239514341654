import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { DataViewBase, DataViewBaseProps } from '../dataview';
import { Table } from './table';
import {Button} from "@/components/button";
import {ChartPieIcon} from "@heroicons/react/24/solid";

export interface DataTableProps<TData, TValue> extends DataViewBaseProps {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  total?: number;
  additionalRows?: React.ReactNode;
}

export const DataTable = <TData, TValue>({
  name,
  noun,
  ctaButton,
  columns,
  data,
  pages = 1,
  currentPage = 0,
  total = 0,
  currentTotal = 0,
  additionalRows,
  onPageRequest,
    exportRoute,
}: DataTableProps<TData, TValue>) => {
  const baseProps: DataViewBaseProps = {
    name,
    noun,
    ctaButton,
    pages,
    total,
    currentTotal,
    currentPage,
    onPageRequest,
    exportRoute
  };

  columns = [
    {
      header: 'No.',
      accessorKey: 'rowNumber',
      cell: (info: any) => (currentPage * 10) - 10 + info.row.index + 1,
    },
    ...columns
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <DataViewBase {...baseProps}>
      <Table className='overflow-x-auto whitespace-nowrap'>
        <thead>
        {table.getRowModel().rows?.length ? (
          table.getHeaderGroups().map((headerGroup) => {
            return (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  );
                })}
              </tr>
            );
          })) : null}
        </thead>
        <tbody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <tr key={row.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className='h-24 text-center bg-white'>
                <div className='flex flex-col items-center gap-6 p-6'>
                  <img
                      src='/assets/empty.png'
                      width={300}
                      height={247}
                      alt=''
                  />
                  <div className='flex flex-col items-center gap-3'>
                    <p className='text-2xl font-medium'>Belum ada {noun}</p>
                    <p className='text-center text-base text-neutral-70'>
                      Saat ini belum terdapat {noun} yang tercatat.
                    </p>
                  </div>
                  <Button element='link' to='/dashboard' leftIcon={<ChartPieIcon />}>
                    Kembali ke dashboard
                  </Button>
                </div>
              </td>
            </tr>
          )}
          {additionalRows}
        </tbody>
      </Table>
    </DataViewBase>
  );
};
DataTable.displayName = 'DataTable';
