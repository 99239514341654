import axios from "axios";
import {headers} from "@/services/headers"
import {ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {loading, getErrors, transactions, transaction, setPaginator, setExportRoute} from "@/reducers/transactions";

export const GetTransactions = (query?: string, page = 1):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .get(process.env.REACT_APP_BASE_URL + '/org/transactions?filter[search]=' + query + '&page=' + page, {
            headers: headers()
        }).then((response) => {
            dispatch(transactions(response.data.data));
            dispatch(setPaginator(response.data.meta));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const ShowTransaction = (id?: string):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .get(process.env.REACT_APP_BASE_URL + '/org/transactions/' + id, {
            headers: headers()
        }).then((response) => {
            dispatch(transaction(response.data.data));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const GetTransactionsByEvent = (code?: string, query?: string):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .get(process.env.REACT_APP_BASE_URL + '/org/events/' + code + '/sales?filter[search]=' + query, {
            headers: headers()
        }).then((response) => {
            dispatch(transactions(response.data.data));
            dispatch(setPaginator(response.data.meta));
            dispatch(setExportRoute(response.data.export_route));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};
