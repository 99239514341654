import React, {useEffect, useState} from "react";
import {Input} from "@/components/form/input";
import {FormLabel} from "@/components/form/formlabel";
import {Button} from '@/components/button';
import {ArrowRightStartOnRectangleIcon, CheckIcon} from "@heroicons/react/24/solid";
import {useDispatch, useSelector} from "react-redux";
import {clearErrors, logout} from "@/reducers/auth"
import {AppDispatch} from "@/root/store";
import {GetCountries} from "@/services/helpers";
import {SendEmailVerification, VerifyCode} from "@/services/auth";

export function Complete() {
    const dispatch: AppDispatch = useDispatch();
    const [formData, setFormData] = useState({
        country_id: '',
        verification_code: '',
        email_verification_code: ''
    });

    const { countries } = useSelector((state: any) => state.helpers);
    const { loading, errors, isLoggedOut, successMsg, successMsgEmail, isSuccess, isVerified } = useSelector((state: any) => state.auth);

    // @ts-ignore
    const user: any = JSON.parse(localStorage.getItem('user'));
    const email = user?.email;

    useEffect(() => {
        if(!user) {
            window.location.href = "/auth/login";
        }

        if(user?.isVerified) {
            window.location.href = "/";
        }
    }, [user])

    useEffect(() => {
        if (isLoggedOut) {
            window.location.href = '/auth/login';
        }
    }, [isLoggedOut]);

    useEffect(() => {
        dispatch(GetCountries())
    }, [dispatch])

    const setData = (e: any, element: string) => {
        setFormData({...formData, [element]: e.target.value})

        dispatch(clearErrors());
    }

    const sendVerificationEmail = () => {
        dispatch(SendEmailVerification())
    }

    const submitVerificationCode = (e: any) => {
        e.preventDefault();

        dispatch(VerifyCode(formData.verification_code, formData.email_verification_code))
    }

    useEffect(() => {
        if(isVerified) {
            window.location.href = "/"
        }
    }, [isVerified])

    return (
        <>
            <div className="text-900 text-3xl font-medium mb-1">Sedikit lagi 👋🏻</div>
            <p className={"text-400 mb-4"}>Tambahkan negara dan nomor hp kamu</p>
            <form onSubmit={submitVerificationCode}>
                <div className={'mt-12'}>
                    <div className="grid grid-cols-3 gap-2 mb-3">
                        {
                            countries.map((item: any, index: string) => (
                                <Button
                                    onClick={() => {
                                        setFormData({...formData, country_id: item.id})
                                    }}
                                    type={'button'}
                                    id={index}
                                    className={'w-full'}
                                    variant={formData.country_id === item.id ? 'primary' : 'neutral'}
                                    look={'fill'}
                                    leftIcon={<img src={item.flags}  alt={item.name}/>}
                                >{item.name}</Button>
                            ))
                        }
                    </div>
                    {!user?.email_verified_at && <div>
                        <div className="mb-3">
                            <FormLabel label='Email' errorText={errors.email} successText={successMsgEmail} required>
                                <Input type='email' readOnly disabled={!formData.country_id} value={email} rightDeco={
                                    <span className={'cursor-pointer'} onClick={sendVerificationEmail}>
                                    Verifikasi
                                </span>}
                                       placeholder={'organizer@gmail.com'} onChange={(e) => setData(e, 'email')}/>
                            </FormLabel>
                        </div>
                        <div className="mb-3">
                            <FormLabel label='Kode Verifikasi Email' errorText={errors["type.email.code"]} required>
                                <Input type='number' min={0} placeholder={'812345'} onChange={(e) => setData(e, 'email_verification_code')} required/>
                            </FormLabel>
                        </div>
                    </div>}
                    <div className={'mt-12 flex flex-col gap-2'}>
                        <Button
                            disabled={!isSuccess}
                            loading={loading}
                            type={'submit'}
                            className={'w-full'}
                            color='neutral'
                            leftIcon={<CheckIcon/>}
                        >Verifikasi</Button>
                        <Button
                            loading={loading}
                            onClick={() => {
                                dispatch(logout())}
                            }
                            type={'submit'}
                            className={'w-full'}
                            variant={'danger'}
                            leftIcon={<ArrowRightStartOnRectangleIcon/>}
                        >Logout</Button>
                    </div>
                </div>
            </form>
        </>
    )
}
