import { configureStore } from '@reduxjs/toolkit'
import authReducer from "@/reducers/auth";
import passwordReducer from "@/reducers/passwords";
import helpersReducer from "@/reducers/helpers";
import dashboardReducer from "@/reducers/dashboard";
import transactionReducer from "@/reducers/transactions";
import eventReducer from "@/reducers/events";
import ticketsReducer from "@/reducers/tickets";
import channelsReducer from "@/reducers/channels";
import financialReducer from "@/reducers/financial";
import profileReducer from "@/reducers/profile";
import guestReducer from "@/reducers/guests";

const store = configureStore({
    reducer: {
        auth: authReducer,
        passwords: passwordReducer,
        helpers: helpersReducer,
        dashboard: dashboardReducer,
        transaction: transactionReducer,
        event: eventReducer,
        tickets: ticketsReducer,
        channels: channelsReducer,
        financial: financialReducer,
        profile: profileReducer,
        guests: guestReducer
    },
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
