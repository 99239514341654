import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    countries: [],
    loading: false,
    errorMsg: '',
    errors: {}
};

const helperSlice = createSlice({
    name: 'helpers',
    initialState,
    reducers: {
        countries: (state, action) => {
            state.countries = action.payload;
        },
        getErrors: (state, action) => {
            state.errorMsg = action.payload.errorMsg;
            state.errors = action.payload.errors;
        },
        clearErrors: (state) => {
            state.errorMsg = '';
            state.errors = '';
        },
        loading: (state, action) => {
            state.loading = action.payload;
        }
    },
});

export const { loading, countries, clearErrors, getErrors } = helperSlice.actions;
export default helperSlice.reducer;
